const actions = {
  SET_STATE: 'chat/SET_STATE',
  GET_DATA: 'chat/GET_DATA',
  GET_ALL_USERS: 'chat/GET_ALL_USERS',
  START_CHAT: 'chat/START_CHAT',
  INIT_CHAT: 'chat/INIT_CHAT',
  SET_MESSAGE: 'chat/SET_MESSAGE',
  ADD_MESSAGE:'chat/ADD_MESSAGE',
    CREATE_CHANNEL: 'chat/CREATE_CHANNEL',
  ADD_CHANNEL: 'chat/ADD_CHANNEL',
  ADD_REQUESTS: 'chat/ADD_REQUESTS',
  SEND_MESSAGE: 'chat/SEND_MESSAGE',
  SUBSCRIBE_CHANNELS: 'chat/SUBSCRIBE_CHANNELS',
  UNSUBSCRIBE_CHANNELS: 'chat/UNSUBSCRIBE_CHANNELS',
  GET_CHANNELS: 'chat/GET_CHANNELS',
  FETCH_MESSAGES: 'chat/FETCH_MESSAGES',
  FETCH_REQUESTS: 'chat/FETCH_REQUESTS',
  FETCH_LEADS: 'chat/FETCH_LEADS',
  SET_HISTORY_MESSAGE: 'chat/SET_HISTORY_MESSAGE',
  SET_INSIDE_CHANNEL: 'chat/SET_INSIDE_CHANNEL',
  COMPOSE_MESSAGE: 'chat/COMPOSE_MESSAGE',
  ASSIGN_EXHIBITOR: 'chat/ASSIGN_EXHIBITOR',
  UNASSIGN_EXHIBITOR: 'chat/UNASSIGN_EXHIBITOR',
  DELETE_REQUEST: 'chat/DELETE_REQUEST',
  SET_NOTIFICATION: 'chat/SET_NOTIFICATION',
  LOAD_MORE_USERS: 'chat/LOAD_MORE_USERS',
  SEARCH_USER: 'chat/SEARCH_USER',
  FOLLOW_ME_TO_LIVE_SESSION: 'chat/FOLLOW_ME_TO_LIVE_SESSION'
  }
  
  export default actions  