export enum HMSAnalyticsLevel {
  VERBOSE,
  INFO,
  ERROR,
  OFF,
}

export enum AnalyticsEventLevel {
  VERBOSE,
  INFO,
  ERROR,
  OFF,
}
