import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  CloseSquare,
  PaperPlus,
} from "react-iconly";

export async function getAdminMenu() {

  return [
    {
      id: "dashboard-page",
      title: <FormattedMessage id="menu.dashboard" />,
      icon: <PaperPlus set="curved" className="remix-icon" />,
      navLink: "/greenroom/dashboard",
      key: "greenroom"
    },
    {
        header: "SETUP",
    },
    {
      id: "event-setup",
      title: <FormattedMessage id="menu.eventsetup" />,
      icon: <PaperPlus set="curved" className="remix-icon" />,
      navLink: "/greenroom/sevent-event",
      key: "eventsetup"
    },
    {
        id: "agenda-setup",
        title: <FormattedMessage id="menu.agendasetup" />,
        icon: <PaperPlus set="curved" className="remix-icon" />,
        navLink: "/greenroom/setup-agenda",
        key: "agendasetup"
    },
    {
        id: "speaker-setup",
        title: <FormattedMessage id="menu.speakersetup" />,
        icon: <PaperPlus set="curved" className="remix-icon" />,
        navLink: "/greenroom/setup-speaker",
        key: "speakersetup"
    },
    {
        id: "live-notification",
        title: <FormattedMessage id="menu.livenotification" />,
        icon: <PaperPlus set="curved" className="remix-icon" />,
        navLink: "/greenroom/live-notification",
        key: "livenotification"
    },
    {
        id: "follow-me",
        title: <FormattedMessage id="menu.followme" />,
        icon: <PaperPlus set="curved" className="remix-icon" />,
        navLink: "/greenroom/follow-me",
        key: "followme"
    },
    {
        header: "ANALYTICS",
    },
    {
      id: "analytics-page",
      title: <FormattedMessage id="menu.analytics" />,
      icon: <PaperPlus set="curved" className="remix-icon" />,
      navLink: "/greenroom/live-analytics",
      key: "analytics"
    },
  ]
}
