import React, { useState,useEffect } from 'react';
import FilerobotImageEditor, { TABS, TOOLS} from 'react-filerobot-image-editor';
import { Modal,Spin } from 'antd';
import {
  RiCloseCircleFill
} from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import actions from '../../../../redux/organizer/eventSetup/actions';
import { uploadFile } from '../../../../services/organizer/eventSetup';

export default function ImageEditor(props) {
  const requiredState = useSelector(({eventSetup})=>({eventSetup}))
  const dispatch = useDispatch();
  const {eventSetup:{imageEditorOpen, imageEditorFile,imageUploadLoading}} = requiredState
  const aspectRatios = []
  const cropAspectRatioPresetArray = {
    "1:1" : {
      titleKey: 'Square',
      descriptionKey: '1:1',
      ratio: 1 / 1,
    },
    "16:9" : {
      titleKey: 'Screen 1920x1080',
      descriptionKey: '16:9',
      ratio: 16 / 9,
    },
    "21:9" : {
      titleKey: 'Cinemascope',
      descriptionKey: '21:9',
      ratio: 21 / 9,
    },
  }
  useEffect(() => {
    props.approvedAspectRatios.forEach(key => {
      aspectRatios.push(cropAspectRatioPresetArray[key])
    });
  }, [props]);

  const closeModal  = () => {
    dispatch({
      type: actions.SET_STATE,
      payload: {
        imageEditorOpen: false,
        imageEditorFile: {}
      },
    })
  }

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8arr], filename, {type:mime});
  }

  const saveImage = async (editedImageObject,designState) => {
    const imgBlob = dataURLtoFile(editedImageObject.imageBase64,editedImageObject.fullName)
    const result = await uploadFile({ file: imgBlob })
    console.log("image uploaded")
    console.log(result)
    props.onImageSave(result.data.fileUrl)
    dispatch({
      type: actions.SET_STATE,
      payload: {
        imageEditorOpen: false,
        imageEditorFile: {},
      },
    })
  }

  console.log("we have an ascpect array")
  console.log(props.approvedAspectRatios)
  console.log(aspectRatios)
  return (
    <Modal
      visible={imageEditorOpen}
      maskClosable={false}
      mask
      width="90vw"
      height="90vh"
      onCancel={closeModal}
      footer={null}
      onClose={closeModal}
      maskStyle={{backgroundColor: "rgba(15, 12, 41, 0.93)"}}
      closeIcon={<RiCloseCircleFill className="remix-icon" />}
      destroyOnClose
    >
      <Spin spinning={imageUploadLoading}>
        <FilerobotImageEditor
          source={imageEditorFile}
          onSave={(editedImageObject, designState) => saveImage(editedImageObject,designState)}
          onClose={closeModal}
          annotationsCommon={{
            fill: '#ff0000'
          }}
          Text={{ text: 'Filerobot...' }}
          Crop={ aspectRatios }
          tabsIds={[TABS.ADJUST, TABS.ANNOTATE, TABS.WATERMARK, TABS.RESIZE]} // or {['Adjust', 'Annotate', 'Watermark']}
          defaultTabId={TABS.ADJUST} // or 'Annotate'
          defaultToolId={TOOLS.TEXT} // or 'Text'
        />
      </Spin>
    </Modal>
  );
}
          