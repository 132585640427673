const actions = {
    SET_STATE: 'organizer/SET_STATE',
    LOGIN: 'organizer/LOGIN',
    SEND_OTP: 'organizer/SEND_OTP',
    LOAD_CURRENT_ACCOUNT: 'organizer/LOAD_CURRENT_ACCOUNT',
    LOGOUT: 'organizer/LOGOUT',
    FAKE_LOGOUT: 'organizer/FAKE_LOGOUT',
    LOGIN_HELPER: 'organizer/LOGIN_HELPER',
    FORGOT_PASSWORD: 'organizer/FORGOT_PASSWORD',
    RESET_PASSWORD: 'organizer/RESET_PASSWORD',
    EDIT_AVATAR: 'organizer/EDIT_AVATAR',
    COMPLETE_EDIT_PROFILE: 'organizer/COMPLETE_EDIT_PROFILE',
    VALIDATE_SESSION: 'organizer/VALIDATE_SESSION'
  }
  
  export default actions  