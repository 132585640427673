import React from 'react'
import { Card, Avatar } from 'antd'
import { CheckCircleTwoTone, CloseCircleTwoTone, ClockCircleTwoTone } from '@ant-design/icons'
import { connect, useDispatch } from 'react-redux'
import actions from 'redux/virtual/streamSession/actions'
// import styles from '../../style.module.scss'

const { Meta } = Card

function QuestionFormat(props) {
  const dispatch = useDispatch()
  const allowMessage = () => {
    const { messageID} = props
    dispatch({
      type: actions.ALLOW_QANDA,
      payload: {
        messageID,
      },
    })
  }

    const { avatarURL, title, question, quesStatus } = props
    let actionOptions = []
    switch (quesStatus) {
      case 'pending':
        actionOptions = [
          <span>
            <ClockCircleTwoTone twoToneColor="#ffcc00" /> Thank you for your response 
          </span>,
        ]
        break
      case 'sentforapproval':
        actionOptions = [
          <span onClick={allowMessage} style={{ cursor: 'pointer' }}>
            <CheckCircleTwoTone twoToneColor="#52c41a" /> Allow &amp; Publish
          </span>,
        ]
        break
      case 'rejected':
        actionOptions = [
          <span>
            <CloseCircleTwoTone twoToneColor="#eb2f96" /> Question Rejected
          </span>,
        ]
        break
      case 'approved':
        actionOptions = [
          <span>
            <CheckCircleTwoTone twoToneColor="#52c41a" /> Approved
          </span>,
        ]
        break
      default:
        actionOptions = []
    }
    return (
      <Card style={{ width: 250 }} actions={actionOptions}>
        <Meta avatar={<Avatar src={avatarURL} />} title={title} description={question} />
      </Card>
    )
  }

export default QuestionFormat
