import { all, takeEvery, call, put,select } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { message } from 'antd'
import actions from './actions'
import { getSavedData } from 'services/organizer/eventSetup'
import {updateRealmScene} from 'services/virtual/realm'
// import menuActions from '../../common/menu/actions'


export function* GET_INIT() {
  try {
    const setupData = yield call(getSavedData)
    console.log(setupData)
    if (Object.keys(setupData.data.realms).length > 0) {
      const final = setupData.data.realms
      yield put({
        type: actions.SET_STATE,
        payload: {
          realms: final,
          currentRealm: Object.keys(final)[0],
        },
      })
      yield put(push(`/realm/${Object.keys(final)[0]}`))
    }
  } catch (err) {
    console.log(err)
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* SAVE_CHANGES() {
  try {
    yield put({
      type: actions.SET_STATE,
      payload: {
        isSavingChanges: true
      },
    })
    const state = yield select()
    const {
      altRealm: {currentRealm,realms}
    } = state
    const theChosenRealm = realms[currentRealm]
    const theData = {
      "products": theChosenRealm.products,
      "videos": theChosenRealm.videos,
      "images": theChosenRealm.images,
      "iframes": theChosenRealm.iframes
    }
    const result = yield call(updateRealmScene, {realmID: currentRealm, categories: theData})
    if (result.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          isSavingChanges: false
        },
      })
      message.success(`Changes have been saved successfully`)
    }
    else{
      message.error(`There is some issue in saving the changes. Kindly contact admin`)
    }
    

  } catch (err) {
    console.log(err)
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_INIT, GET_INIT),
    takeEvery(actions.SAVE_CHANGES, SAVE_CHANGES)
  ])
}