const organizerConfig = {
    version: "1.0",
    theme: "light", // light - dark
    contentWidth: "full", // full - boxed
    sidebarCollapsed: true,
    sidebarCollapseButton: true,
    layout: "VerticalLayout", // VerticalLayout - HorizontalLayout
    navigationFull: false,
    navigationBg: false,
    direction: "ltr", // ltr - rtl
    publishKey: 'pub-c-35cb7322-2829-435a-98fe-389f2cbb41f2',
    subscribeKey: 'sub-c-0f169d52-a289-11ea-9123-e6a08f73ae22',
    channel : 'wiztales-notifications-altyug'
}

export default organizerConfig