const actions = {
  GET_INIT: 'stageSetup/GET_INIT',
  SET_STATE: 'stageSetup/SET_STATE',
  GET_DATA: 'stageSetup/GET_DATA',
  SAVE_DATA: 'stageSetup/SAVE_DATA',
  SET_STAGE: 'stageSetup/SET_STAGE',
  DELETE_STAGE: 'stageSetup/DELETE_STAGE',
}

export default actions
