import React from 'react'
import { all, takeEvery, call, put, select } from 'redux-saga/effects'
import { notification, message } from 'antd'
import capitalizeFirstLetters from 'capitalize-first-letters'
import {
  login,
  logout,
  forgotPassword,
  resetPassword,
  editProfile,
  sendOTP,
  saveFailedLogin,
  validateSession
} from '../../../services/virtual/user'
import actions from './actions'

export function* LOGIN(data) {
  try {
    const result = yield call(login, { finalData: data.payload })
    if (result.status === 200) {
      if (result.data.active_status) {
        if (!result.data.kick_out) {
          if (!result.data.login_status) {
            if (result.data.payment_status) {
              localStorage.setItem('acctoken', result.data.accessToken)
              localStorage.setItem('userid', result.data.id)
              localStorage.setItem('tokenType', result.data.tokenType)
              localStorage.setItem('userData', JSON.stringify(result.data.userData))
              yield put({
                type: actions.LOGIN_HELPER,
                payload: {
                  userData: result.data.userData,
                  id: result.data.id,
                  tokenType: result.data.tokenType,
                  accessToken: result.data.accessToken,
                },
              })
            } else {
              notification.error({
                message: 'Trouble with account',
                duration: 12,
                description:
                  'Your account is not yet approved. It could be because of incomplete registration process. Kindly contact the admin right now.',
              })
              localStorage.clear()
              yield put({
                type: actions.SET_STATE,
                payload: {
                  loading: false,
                },
              })
            }
          } else {
            notification.error({
              message: 'Multiple Session Detected',
              duration: 12,
              description: `Your account is logged in another device. Kindly logout from the other device to login here. If you think this is an issue, kindly contact the admin or email us at ${process.env.REACT_APP_SUPPORT_EMAIL}`,
            })
            localStorage.clear()
            yield put({
              type: actions.SET_STATE,
              payload: {
                loading: false,
              },
            })
          }
        } else {
          notification.error({
            message: 'Access Restricted',
            duration: 12,
            description:
              `You do not have the access for this event. If you think this is a mistake, kindly email us at ${process.env.REACT_APP_SUPPORT_EMAIL}`,
          })
          localStorage.clear()
          yield put({
            type: actions.SET_STATE,
            payload: {
              loading: false,
            },
          })
        }
      } else {
        notification.error({
          message: "We haven't started yet.",
          duration: 12,
          description: `The conference will commence at 12 PM HKT on 20th April 2022.`,
        })
        localStorage.clear()
        yield put({
          type: actions.SET_STATE,
          payload: {
            loading: false,
          },
        })
      }
    } else {
      notification.error({
        message: 'Status Invalid',
        duration: 12,
        description:
          'The details entered for logging in are either incorrect or the user is not registered.',
      })
      localStorage.clear()
      yield put({
        type: actions.SET_STATE,
        payload: {
          loading: false,
        },
      })
    }
  } catch (err) {
    console.log(err.response)
    const result = err.response
    if (result.status === 401) {
      notification.error({
        message: 'Unauthorized Access',
        description:
          'The details entered for logging in are either incorrect or the user is not registered.',
      })

      yield put({
        type: actions.SET_STATE,
        payload: {
          loading: false,
        },
      })
    }
    yield call(saveFailedLogin, { email: data.payload.numberOrEmail, deviceInfo: data.payload.deviceInfo })

  }
}

export function* LOGIN_HELPER(data) {
  try {
    const detailResult = data.payload.userData
    const helperMessage = <p>Welcome to {process.env.REACT_APP_EVENT_NAME}</p>
    notification.success({
      message: `Hi ${capitalizeFirstLetters(detailResult.name)}`,
      duration: 8,
      description: helperMessage,
    })

    yield put({
      type: actions.SET_STATE,
      payload: {
        isTutorialVideoVisible: true,
      },
    })

    // yield put({
    //   type: actions.SET_STATE,
    //   payload: {
    //     isEditProfileOpen: true
    //   }
    // })

    console.log('testing this')
    console.log(detailResult)

    if (detailResult.roles.includes('ROLE_STALL_CONFIGURATOR')) {
      yield put({
        type: stallConfigActions.SET_STATE,
        payload: {
          isStallDesignerOpen: true,
        },
      })
    }

    yield put({
      type: actions.SET_STATE,
      payload: {
        id: data.payload.id,
        name: capitalizeFirstLetters(detailResult.name),
        number: `+${detailResult.number}`,
        roles: detailResult.roles,
        email: detailResult.email,
        raffle: detailResult.rafale,
        organization: capitalizeFirstLetters(detailResult.organization),
        designation: capitalizeFirstLetters(detailResult.designation),
        avatarType: detailResult.avatar_type,
        avatarData: detailResult.avatar_data,
        avatarColor: detailResult.avatar_color,
        accessToken: data.payload.accessToken,
        tokenType: data.payload.tokenType,
        assignedScene: detailResult.assignedScene,
        authorized: true,
        loading: false,
      },
    })

    // yield put({
    //   type: menuActions.GET_DATA,
    // })

    // yield put({type: actions.GET_ALL_RATINGS})

    // yield put({
    //   type: streamActions.SET_STATE,
    //   payload: {
    //     assignedSessions: detailResult.assignedSessions,
    //   },
    // })
  } catch (err) {
    console.log(err)
    // const result = err.response
    // if (result.status === 401) {
    //   notification.error({
    //     message: 'Unauthorized Access',
    //     duration: 12,
    //     description:
    //       'The details entered for logging in are either incorrect or the user is not registered.',
    //   })

    //   yield put({
    //     type: actions.SET_STATE,
    //     payload: {
    //       loading: false,
    //     },
    //   })
    // }
  }
}

export function* LOGOUT() {
  // yield call(logout)
  localStorage.clear()
  const state = yield select()
  const {
    virtualUser: { accessToken },
  } = state

  yield call(logout, {
    token: accessToken,
  })

  yield put({
    type: actions.SET_STATE,
    payload: {
      id: '',
      name: '',
      number: '',
      roles: '',
      email: '',
      organization: '',
      designation: '',
      avatarType: '',
      avatarData: '',
      avatarColor: '',
      accessToken: '',
      tokenType: '',
      assignedScene: '',
      authorized: false,
      loading: false,
    },
  })

  window.location.href = '/'
}

export function* RESET_PASSWORD(data) {
  try {
    yield put({
      type: actions.SET_STATE,
      payload: {
        resetLoading: true,
      },
    })
    const result = yield call(resetPassword, {
      id: data.payload.id,
      password: data.payload.password
    })
    if (result.status === 200) {
      console.log(result)
      yield put({
        type: actions.SET_STATE,
        payload: {
          resetLoading: false,
          resetSuccess: true
        },
      })
      notification.success({
        message: 'Password Reset is now successful',
        duration: 12,
        description: 'Kindly login with your new password.',
      })
    }
  } catch (err) {
    console.log(err.response)
    const result = err.response
    if (result.status === 401) {
      notification.error({
        message: 'Password Reset Unsuccessful',
        duration: 12,
        description: `Kindly write to us at ${process.env.REACT_APP_SUPPORT_EMAIL}`,
      })

      yield put({
        type: actions.SET_STATE,
        payload: {
          forgotLoading: false,
        },
      })
    }
  }
}

export function* FORGOT_PASSWORD(data) {
  try {
    const result = yield call(forgotPassword, {
      userDetails: data.payload.userDetails,
    })
    if (result.status === 200) {
      console.log(result)
      yield put({
        type: actions.SET_STATE,
        payload: {
          forgotLoading: false,
        },
      })
      notification.success({
        message: 'Email Successfully sent',
        duration: 12,
        description:
          "Kindly check your email for password recovery link. Don't forget to check your inbox, promotions tab, updates tab or spam for the email.",
      })
    }
  } catch (err) {
    console.log(err.response)
    const result = err.response
    if (result.status === 401) {
      notification.error({
        message: 'User not found with this User ID / Email ID',
        duration: 12,
        description: `We could not find a user registered with mentioned Email ID / User ID. Kindly write to us at ${process.env.REACT_APP_SUPPORT_EMAIL}`,
      })

      yield put({
        type: actions.SET_STATE,
        payload: {
          forgotLoading: false,
        },
      })
    }
  }
}

export function* EDIT_AVATAR(data) {
  try {
    const config = {
      onUploadProgress: progressEvent => {
        const percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total)
        data.payload.onProgress({ percent: percentCompleted })
      },
    }
    const result = yield call(uploadFile, { file: data.payload.file, config })
    if (result.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          avatarType: 'image',
          avatarData: result.data.fileUrl,
        },
      })

      yield put({
        type: actions.COMPLETE_EDIT_PROFILE
      })

      const analyticsresult = yield put({
        type: actions.UPDATE_ELEMENT_SCORE,
        payload: {
          touchPointId: 4,
          elementId: 'editProfilefixedElementID',
          touchPointName: 'Change Profile Picture'
        },
      })

      data.payload.onSuccess('ok')
      message.success('Image Uploaded Successfully')
      
      if(analyticsresult.status === 200){
        notification.success({
          message: 'Points Added',
          duration: 5,
          description:
            'Points have been successfully added to the leaderboard.',
        })
      }

    } else {
      data.payload.onError({ event: result.statusText })
      message.error(
        'Error occured in uploading the Image. Kindly Choose a normal Avatar as of now.',
      )
    }
  } catch (err) {
    data.payload.onError({ event: err.response.data.message })
    console.log(err.response)
    message.error(err.response.data.message)
  }
}

export function* COMPLETE_EDIT_PROFILE() {
  try {
    const state = yield select()
    const {
      virtualUser: { name, avatarType, avatarData, avatarColor, accessToken },
    } = state
    const finalValue = {
      name,
      avatarType,
      avatarData,
      avatarColor,
    }
    yield put({
      type: actions.SET_STATE,
      payload: {
        editProfileLoading: true,
      },
    })
    const result = yield call(editProfile, { finalData: finalValue, token: accessToken })
    if (result.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          isEditProfileOpen: false,
          editProfileLoading: false,
          // isTutorialVideoVisible: true,
        },
      })
      message.success('Profile Edited Succesfuly')
    } else {
      message.error(`${result.status}: ${result.statusText} - Error Occurred`)
    }
  } catch (err) {
    console.log(err.response)
    message.error(err.response.data.message)
  }
}

export function* SEND_OTP() {
  try {
    const state = yield select()
    const {
      virtualUser: { firstName, lastName, email, deviceInfo },
    } = state
    const result = yield call(sendOTP, { firstName, lastName, email, deviceInfo })
    console.log('The final result')
    console.log(result)
    if (result.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          otpLoading: false,
          currentScreen: 1,
        },
      })
      message.success(`OTP sent successfully to ${email}`)
    } else if (result.status === '401') {
      message.error(`Issue in sending the email. Kindly recheck you email id.`)
      yield put({
        type: actions.SET_STATE,
        payload: {
          otpLoading: false,
        },
      })
    } else {
      message.error(`${result.status}: ${result.statusText} - Error Occurred`)
    }
  } catch (err) {
    notification.error({
      message: 'User not Registered',
      duration: 20,
      description: `We could not find a user registered with mentioned Email ID / User ID. Kindly write to us at ${process.env.REACT_APP_SUPPORT_EMAIL}`,
    })
    // message.error(`Issue with email. Retry sending the OTP.`)
    yield put({
      type: actions.SET_STATE,
      payload: {
        otpLoading: false,
      },
    })
  }
}

export function* FAKE_LOGOUT() {
  // yield call(logout)
  const state = yield select()
  const {
    virtualUser: { accessToken },
  } = state

  yield call(logout, {
    token: accessToken,
  })
}

export function* VALIDATE_SESSION(data) {
  try {

    const result = yield call(validateSession, {token: data.payload.accessToken})
    if(result.status === 200){
      yield put({
        type: 'virtualuser/LOGIN_HELPER',
        payload: {
          accessToken: data.payload.accessToken,
          id: data.payload.id,
          tokenType: data.payload.tokenType,
          userData: data.payload.userData,
        },
      })
    }
  } catch (err) {
    console.log(err)
    yield put({
      type: actions.SET_STATE,
      payload: {
        accessToken: data.payload.accessToken,
      },
    })
    yield put({
      type: 'virtaluser/LOGOUT',
    })
    notification.error({
      message: 'Session Timed Out',
      duration: 12,
      description:
        'Your Session has expired. We request you to kindly re-login into the application.',
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOGIN_HELPER, LOGIN_HELPER),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.FAKE_LOGOUT, FAKE_LOGOUT),
    takeEvery(actions.FORGOT_PASSWORD, FORGOT_PASSWORD),
    takeEvery(actions.RESET_PASSWORD, RESET_PASSWORD),
    takeEvery(actions.EDIT_AVATAR, EDIT_AVATAR),
    takeEvery(actions.COMPLETE_EDIT_PROFILE, COMPLETE_EDIT_PROFILE),
    takeEvery(actions.SEND_OTP, SEND_OTP),
    takeEvery(actions.VALIDATE_SESSION, VALIDATE_SESSION),

  ])
}