import React from 'react';
import Viewer from 'react-viewer';
import { useDispatch, useSelector } from "react-redux";
import actions from '../../../../redux/virtual/altRealm/actions';

export default function ImagePop() {
  const requiredState = useSelector(({altRealm})=>({altRealm}))
  const dispatch = useDispatch();
  const {altRealm:{imagePopOpen,imagePopURL}} = requiredState


  const closePop  = () => {
    dispatch({
      type: actions.SET_STATE,
      payload: {
        imagePopOpen: false,
        imagePopURL: "",
      },
    })
  }

  return (
    <Viewer
      visible={imagePopOpen}
      onClose={closePop}
      images={[{src: imagePopURL, alt: ""}]}
      rotatable={false}
      scalable={false}
      onMaskClick={closePop}
      drag={false}
      noNavbar
      noImgDetails
      changeable={false}
      // noToolbar
      // noFooter
      style={{paddingRight: 0}}
    />
  );
}
          