import React, { useEffect, useState } from "react";
import { Tabs, Row, Col, Skeleton } from "antd";
import { useSelector, useDispatch } from "react-redux";
import actions from "redux/virtual/streamSession/actions";
import stageActions from "redux/organizer/stageSetup/actions"
import QuestionAnswer from './MainComponents/QuestionAnswer'
import LiveChat from './MainComponents/LiveChat'
import LivePolling from './MainComponents/LivePolling'
import "./streamFeatures.less"
// import RaiseHandContent from './LayoutComponent/RaiseHandContent'
import {
  SendOutlined,
  QuestionCircleFilled,
  BarChartOutlined,
  CloseCircleOutlined,
  UserSwitchOutlined
  // ExpandAltOutlined,
} from '@ant-design/icons'
import QnAContent from "view/virtual/widgets/Streamfeature/LayoutComponent/QnAContent";
import ChatFooter from "view/virtual/widgets/Streamfeature/LayoutComponent/ChatFooter";
import PollingContent from "view/virtual/widgets/Streamfeature/LayoutComponent/PollingContent";
import ChatPollingFooter from "view/virtual/widgets/Streamfeature/LayoutComponent/ChatPollingFooter";
import { isMobile } from "react-device-detect";

const { TabPane } = Tabs

export default function StreamArena(props) {
  const dispatch = useDispatch();
  const requiredState = useSelector(({ streamSession, stageSetup }) => ({ streamSession, stageSetup }));

  const { streamSession: { selectedStage, currentScreen }, stageSetup: { stages } } = requiredState;
  let chatToggle, qandaToggle, pollingToggle, raiseHandToggle = false

  if (Object.keys(stages).length > 0 && selectedStage !== "") {
    const stageDetails = stages[selectedStage]
    chatToggle = stageDetails.chatToggle
    qandaToggle = stageDetails.qandaToggle
    pollingToggle = stageDetails.pollingToggle
    raiseHandToggle = stageDetails.raiseHandToggle
    // ({chatToggle,qandaToggle,pollingToggle,raiseHandToggle} = stageDetails)
  }

  const changeTab = selection => {
    let counterType = ''
    if (selection === 'liveChat') {
      counterType = 'unreadChatCount'
    } else if (selection === 'qanda') {
      counterType = 'unreadqndaCount'
    } else {
      counterType = 'unreadPollingCount'
    }
    dispatch({
      type: actions.SET_STATE,
      payload: {
        currentScreen: selection,
        [counterType]: 0,
      },
    })
  }

  
  return <></>
    // <Row className="streamTabs">
    //   <Col span={24}>
    //     <Tabs
    //       size="small"
    //       animated={{ inkBar: true, tabPane: false }}
    //       defaultActiveKey={currentScreen}
    //       activeKey={currentScreen}
    //       onChange={changeTab}
    //       centered
    //     >
    //       {chatToggle ? (
    //         <TabPane
    //           tab={
    //             <span className="hp-text-center tabHeading">
    //               <i className="ri-chat-1-fill" />
    //               <br />
    //               Chat
    //             </span>
    //           }
    //           key="liveChat"
    //         >
    //           <LiveChat />
    //         </TabPane>
    //       ) : (
    //         ''
    //       )}
    //       {qandaToggle ? (
    //         <TabPane
    //           tab={
    //             <span className="hp-text-center tabHeading">
    //               <i className="ri-questionnaire-fill" />
    //               <br />
    //               Q&amp;A
    //             </span>
    //           }
    //           key="qanda"
    //         >
    //           <QnAContent leftHeight={'23rem'} />
    //           <ChatFooter panelType="qna" />
    //         </TabPane>
    //       ) : (
    //         ''
    //       )}
    //       {pollingToggle ? (
    //         <TabPane
    //           tab={
    //             <span className="hp-text-center tabHeading">
    //               <i className="ri-chat-poll-fill" />
    //               <br />
    //               Polls
    //             </span>
    //           }
    //           key="polling"
    //         >
    //           <Row>
    //             <Col span={24}>
    //             <PollingContent leftHeight={'23rem'} />

    //             </Col>
    //             <Col span={24}>
    //               <Row className="footerWidget">
    //                 <Col span={24}>
    //                 <ChatPollingFooter />
    //                 </Col>
    //                 <Col span={24}>
    //                 <Reactions />
    //                 </Col>
    //               </Row>
    //             </Col>
    //           </Row>
    //         </TabPane>
    //       ) : (
    //         ''
    //       )}
    //       {raiseHandToggle ? (
    //         <TabPane
    //           tab={
    //             <span>
    //               <UserSwitchOutlined />
    //               <br />
    //               Raise Hand
    //             </span>
    //           }
    //           key="raisehand"
    //         >
    //           {/* <RaiseHandContent /> */}
    //           <h2>Raise Hand</h2>
    //         </TabPane>
    //       ) : (
    //         ''
    //       )}
    //     </Tabs>
    //   </Col>
    // </Row>
  // )
}
