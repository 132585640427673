// import React from 'react'
// import { FormattedMessage } from 'react-intl'
// import {
//   Calendar,
//   Game,
//   Filter,
//   Home,
//   People
// } from "react-iconly";

export async function getEventMenu(allScenes) {
  console.log("inside event menu")
  console.log(allScenes)
  const menuMap = Object.entries(allScenes).map((key) => {
    return(
      {
        id: key[0],
        title: key[1].name,
        icon: key[1].imageUrl,
        navLink: `/event/${key[0]}`,
        key: key[0],
        header: key[1].name,
      }
    )
  })
  return menuMap
}
