import React, { Suspense } from "react";

// import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import { ConnectedRouter } from 'connected-react-router'
import store, { history } from './redux/store'
import AuthRoute from "./auth"
import "./assets/icons/remixicon.css";
import "./assets/less/yoda-theme.less";
import { HMSRoomProvider } from "@100mslive/react-sdk";
// import * as serviceWorker from './serviceWorker';
// import * as serviceWorker from '%PUBLIC_URL%/worker.js';

import App from "./App";


ReactDOM.render(
  <Suspense fallback="loading">
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <AuthRoute>
        <HMSRoomProvider>
          <App />
        </HMSRoomProvider>
        </AuthRoute>
      </ConnectedRouter>
    </Provider>
  </Suspense>,
  document.getElementById("root")
);

// serviceWorker.register();