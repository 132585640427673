const actions = {
  SET_STATE: 'streamSession/SET_STATE',
  SET_MESSAGE: 'streamfeature/SET_MESSAGE',
  SEND_MESSAGE: 'streamSession/SEND_MESSAGE',
  SET_QUESTION: 'streamSession/SET_QUESTION',
  SET_POLLING: 'streamSession/SET_POLLING',
  FETCH_MESSAGES: 'streamSession/FETCH_MESSAGES',
  FETCH_QUESTIONS: 'streamSession/FETCH_QUESTIONS',
  SEND_QUESTION: 'streamSession/SEND_QUESTION',
  SEND_CREATE_POLL: 'streamSession/SEND_CREATE_POLL',
  ALLOW_QANDA: 'streamSession/ALLOW_QANDA',
  ALLOW_POLL: 'streamSession/ALLOW_POLL',
  HIDE_POLL: 'streamSession/HIDE_POLL',
  ANSWER_POLL: 'streamSession/ANSWER_POLL',
  FETCH_POLL: 'streamSession/FETCH_POLL',
  EMAIL_NOTE: 'streamSession/EMAIL_NOTE',
  RAISE_HAND: 'streamSession/RAISE_HAND',
  REVOKE_HAND: 'streamSession/REVOKE_HAND',
  SET_RAISE_HAND: 'streamSession/SET_RAISE_HAND',
  FETCH_RAISE_HANDS: 'streamSession/FETCH_RAISE_HANDS',
  FETCH_ALL_RAISE_HANDS: 'streamSession/FETCH_ALL_RAISE_HANDS',
  APPROVE_RAISE_HAND: 'streamSession/APPROVE_RAISE_HAND',
  VALIDATE_RAISE_HAND: 'streamSession/VALIDATE_RAISE_HAND',
  RELEASE_VMIX_CALL: 'streamSession/RELEASE_VMIX_CALL',
  JOIN_SESSION: 'streamSession/JOIN_SESSION'
}

export default actions
