import mixpanel from 'mixpanel-browser';
mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_KEY, {debug: true}); 

 export default function Analytic(props) {
  console.log(props);
    const {key,value} = props
    console.log(props,":::::");
const batchData = {...value,event:process.env.REACT_APP_EVENT_NAME}    
    function analyseData (){
    mixpanel.track(key,batchData)
    }
  return (
    analyseData()
  )
}
