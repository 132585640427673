import React, { createRef, useState, useEffect } from "react";
import {
  Col,
  Tooltip,
  Row,
  Space,
  Popover,
  Avatar,
  Divider,
  List,
  Skeleton,
  Input,
  Badge,
} from "antd";
const { Search } = Input;
import Altyug from "assets/images/logo/png/logo-dark.png";
import Hearts from "assets/images/emojis/red-heart.png";
import Clapping from "assets/images/emojis/clapping-hands.png";
import Laugh from "assets/images/emojis/face-with-tears-of-joy.png";
import Fire from "assets/images/emojis/fire.png";
import UseAnimations from "react-useanimations";
import microphone from "react-useanimations/lib/microphone2";
import volume from "react-useanimations/lib/volume";
import rtcFrameworkActions from "redux/virtual/rtcFramework/actions";
import streamActions from "redux/virtual/streamSession/actions";
import userActions from "redux/virtual/user/actions";
import one from "assets/images/icons/1.png";
import two from "assets/images/icons/2.png";
import three from "assets/images/icons/3.png";
import four from "assets/images/icons/4.png";
import five from "assets/images/icons/5.png";
import clap from "assets/images/icons/b.png";
import capture from "assets/images/icons/c.png";
import LeaderBoard from "../LeaderBoard";

import {
  RiCamera3Line,
  RiBodyScanLine,
  RiChat1Line,
  RiQuestionLine,
  RiEmotion2Line,
  RiVolumeMuteLine,
  RiVolumeUpLine,
  RiChatPollLine,
  RiNotification2Line,
  RiBarChart2Fill,
} from "react-icons/ri";
import {FiMic,FiMicOff} from "react-icons/fi"
import actions from "redux/virtual/altRealm/actions";
import {
  useHMSActions,
  useHMSStore,
  selectIsLocalAudioEnabled,
  selectPeersByRole,
  selectLocalPeer,
  selectPeerAudioByID,
  selectSpeakers,
  selectDominantSpeaker,
  selectAudioTrackByPeerID,
  selectAudioPlaylist,
  selectPeerMetadata,
  selectLocalPeerID
} from "@100mslive/react-sdk";
import "./interactions.less";
import { useDispatch, useSelector } from "react-redux";
import Instructions from "view/common/components/Instructions";
import HeaderUser from "../header/HeaderUser";
import LiveChat from "view/virtual/pages/StreamActions/MainComponents/LiveChat";
import StreamFeature from "view/virtual/widgets/Streamfeature";
import PollingContent from "view/virtual/pages/StreamActions/LayoutComponent/PollingContent";
import ChatPollingFooter from "view/virtual/pages/StreamActions/LayoutComponent/ChatPollingFooter";
import { isMobile, isMobileOnly, isTablet } from "react-device-detect";
import Analytic from "configs/analytic";
import { filter } from "lodash";

export default function Interactions() {
  const requiredState = useSelector(
    ({ gameContext, rtcFramework, altRealm, virtualUser, streamSession }) => ({
      gameContext,
      rtcFramework,
      altRealm,
      virtualUser,
      streamSession,
    })
  );
  const {
    rtcFramework: {
      peerDetails,
      isMuteAll,
      isMute,
      zg,
      localStream,
      isChatOpen,
      isPollOpen,
      isMuteIndividual,
    },
    virtualUser: { isLeaderBoardOpen, isNotificationOpen },
    streamSession: { unreadChatCount, currentScreen },
  } = requiredState;
  const { screenShot, contex } = requiredState?.gameContext;
  const audioEnabled = useHMSStore(selectIsLocalAudioEnabled);
  const localpeer = useHMSStore(selectLocalPeer);
  const localPeerId = useHMSStore(selectLocalPeerID);
  const allSpeakers = useHMSStore(selectSpeakers);
  const track  = useHMSStore(selectPeerAudioByID(localpeer?.id))
  const dominantSpeaker = useHMSStore(selectDominantSpeaker);
  console.log(localpeer, allSpeakers, dominantSpeaker);
  const LocalmetaData = useHMSStore(selectPeerMetadata(localPeerId));
console.log(LocalmetaData,"LocalmetaData");
  const audioList = useHMSStore(selectAudioPlaylist.list);
  const peers = useHMSStore(selectPeersByRole("audioparticipant"));
console.log(audioList,"audioList");
  const hmsActions = useHMSActions();
  const [canvas, setCanvas] = useState(null);
  const [ProfileImage, setProfileIamge] = useState("");
  const [navigationArea, setnavigationArea] = useState([
    { name: "Auditorium", image: one },
    { name: "Nomination Area", image: two },
    { name: "Arcade Games", image: three },
    { name: "Spawning area", image: four },
    { name: "Photobooth", image: five },
  ]);
  const [filterInput, setFilterInput] = React.useState("");
  const [searchVal, setSearchVal] = useState("");
console.log(peerDetails,"peerDetails");
  useEffect(
    function () {
      if (contex !== null) {
        contex.on("canvas", setCanvas);
      }
    },
    [contex]
  );
  useEffect(() => {
    if (isChatOpen) {
      dispatch({
        type: streamActions.SET_STATE,
        payload: {
          currentScreen: isChatOpen ? "liveChat" : 0,
        },
      });
    }
  }, [isChatOpen]);

useEffect(() => { 

 if(peers && peers.length>0){


  const newRes = peers && peers?.map(obj => {
    console.log(obj,"obj")

      // return { ...obj, isActive: JSON.parse(obj.metadata)?.isActive };
    return  { ...obj, isActive: false };
  });


  dispatch({
    type: rtcFrameworkActions.SET_STATE,
    payload: {
      peerDetails: newRes,
    },
  });

 }

}, [peers])

  
  const CaptureScreen = () => {
    let batchData = { subKey: "captureScreen" };
    const analyticData = {
      key: "Interaction",
      value: batchData,
    };
    Analytic(analyticData);

    dispatch({
      type: actions.SEND_UNITY_MESSAGE,
      payload: {
        gameObjectName: "CaptureScreen",
        methodName: "TakeScreenShot",
      },
    });
  };
  const dispatch = useDispatch();
  const openAvatarCustomizer = () => {
    let batchData = { subKey: "CustomizeAvatar" };
    const analyticData = {
      key: "Interaction",
      value: batchData,
    };
    Analytic(analyticData);

    dispatch({
      type: "altRealm/SET_STATE",
      payload: {
        isAvatarCustomizerOpen: true,
        isAvatarInitial: false,
      },
    });
  };
  const openInstructions = () => {
    let batchData = { subKey: "openInstructions" };
    const analyticData = {
      key: "Interaction",
      value: batchData,
    };
    Analytic(analyticData);
    dispatch({
      type: "altRealm/SET_STATE",
      payload: {
        // isInstructionsOpen: true,
        runonboarding: true,
      },
    });
  };

  const openEditor = () => {
    dispatch({
      type: "realmEditor/SET_STATE",
      payload: {
        isRealmEditorOpen: true,
        currentStep: 0,
      },
    });
  };

  const handleMute = async (val) => {
    if (val && audioEnabled) {
      await hmsActions.setLocalAudioEnabled(false);
    } else {
      await hmsActions.setLocalAudioEnabled(true);
      dispatch({
        type: rtcFrameworkActions.SET_STATE,
        payload: {
          isMuteAll: false,
        },
      });
      setVolumeByRole(100);
    }
    // const newState = peerDetails.map(obj => {
    //   if (obj.id === localpeer?.id) {
    //     return {...obj, isActive: !obj.isActive};
    //   }
    //   return obj;
    // });
    console.log(LocalmetaData)
    const newMetadata = { ...LocalmetaData, isActive: !val };
    await hmsActions.changeMetadata(newMetadata);

    dispatch({
      type: rtcFrameworkActions.SET_STATE,
      payload: {
        // peerDetails:newState,
        isMute: val,
      },
    });
  };

  function setVolumeByRole(volume) {
    for (const peer of peers) {
      if (peer.audioTrack) {
        hmsActions.setVolume(volume, peer.audioTrack);
      }
    }
  }

 

    function handleMuteIndividual(peer, volume) {
console.log(peer,volume);
    if(peer){
      hmsActions.setVolume(volume, peer?.audioTrack);
      
    }

    // const track  = useHMSStore(selectAudioTrackByPeerID(peer?.id))
    // const peer1 = hmsStore.getState(selectPeerById(peer?.id));
    const newState = peerDetails.map(obj => {
      if (obj.id === peer?.id && JSON.parse(peer.metadata).isActive) {
        return {...obj, isActive: !obj.isActive};
      }
      return obj;
    });

  
    dispatch({
      type: rtcFrameworkActions.SET_STATE,
      payload: {
        peerDetails:newState,

        isMuteIndividual: !isMuteIndividual,
      },
    });
  }

  const handleMuteAll = async (val) => {
    if (val) {
      setVolumeByRole(0);
    } else {
      setVolumeByRole(100);
    }
    dispatch({
      type: rtcFrameworkActions.SET_STATE,
      payload: {
        isMuteAll: val,
      },
    });
  };

  const openStreamModule = (val) => {
    if (val == "livechat") {

      let batchData = { subKey: val };
      const analyticData = {
        key: "Interaction",
        value: batchData,
      };
      Analytic(analyticData);
      dispatch({
        type: streamActions.SET_STATE,
        payload: {
          currentFeature: val,
          unreadChatCount :0
        },
      });
      dispatch({
        type: rtcFrameworkActions.SET_STATE,

        payload: {
          isChatOpen: !isChatOpen,
          isPollOpen: false,
        },
      });
    } else if (val == "Polling") {
      let batchData = { subKey: val };
      const analyticData = {
        key: "Interaction",
        value: batchData,
      };
      Analytic(analyticData);
      dispatch({
        type: rtcFrameworkActions.SET_STATE,

        payload: {
          isPollOpen: !isPollOpen,
          isChatOpen: false,
        },
      });
    } else {
      let batchData = { subKey: "leaderBoard" };
      const analyticData = {
        key: "Interaction",
        value: batchData,
      };
      Analytic(analyticData);
      dispatch({
        type: userActions.GET_LEADERBOARD,
        payload: {},
      });
      dispatch({
        type: userActions.SET_STATE,
        payload: {
          isLeaderBoardOpen: !isLeaderBoardOpen,
        },
      });
    }
  };
  const OpenNotifications = () => {
    dispatch({
      type: userActions.SET_STATE,
      payload: {
        isNotificationOpen: !isNotificationOpen,
      },
    });
  };

  const sendReaction = (val) => {
    let batchData = { subKey: val };
    const analyticData = {
      key: "Send Reaction",
      value: batchData,
    };
    Analytic(analyticData);

    console.log(val);
    dispatch({
      type: actions.SEND_UNITY_MESSAGE,
      payload: {
        gameObjectName: "Reactions",
        methodName: "DoReaction",
        parameter: val,
      },
    });
  };
  const handleQUickNavigation = (nav) => {
    dispatch({
      type: actions.SEND_UNITY_MESSAGE,
      payload: {
        gameObjectName: "Player",
        methodName: "SendPlayer",
        parameter: nav,
      },
    });
  };

  function checkSpeaker(item) {
    return Object.values(allSpeakers).some((value) => value.peerID == item);
  }

  const filterData = (searchInput) => {
    const newFilter = peers.filter((value) => {
      return value.name.toLowerCase().includes(searchInput.toLowerCase());
    });
    if (searchInput === "") {
      setFilterInput("");
    } else {
      setFilterInput(newFilter);
    }
  };
 const checkMicAction = (index)=>{
console.log(index)
if(index === 0){
  return isMute
}
 }
  return (
    <>
      <Instructions />
      <Row
        className="interactionLayerTop"
        align="middle"
        justify="space-between"
      >
        <Col lg={2} md={2} sm={6} xs={8} className="logoArea">
          <img src={Altyug} alt="logo" style={{ width: "100%" }} />
        </Col>
        {!isMobile && peerDetails && peerDetails.length > 0 ? (
          
          <Popover
            content={
              <>
                <Input
                  placeholder="Search player"
                  onChange={(e) => filterData(e.target.value)}
                  style={{ width: 200 }}
                />

                <List
                  itemLayout="horizontal"
                  dataSource={filterInput == "" ? peerDetails : filterInput}
                  renderItem={(item, index) => (
                    
                    <List.Item>
                      <List.Item.Meta
                        avatar={
                          <Avatar
                            style={
                              JSON.parse(item?.metadata)?.isActive ?
                              checkSpeaker(item.id) 
                                ? { border: "5px solid green" }
                                : "" :{ border: "1px solid green" }
                            }
                            src={JSON.parse(item?.metadata)?.image}
                            className="hp-cursor-pointer"
                          />
                          
                        }
                        
                        title={<a href="#" style={{fontSize:"10px",display:"flex"}}>{item?.name}</a>}
                      />

                      <Row
                        className="avtarListMic"
                        align="middle"
                        justify="space-between"
                        key={index}
                      >
                        <Col
                          span={3}
                          className="leftPanel text-center"
                          style={{ marginLeft: "1vw" }}
                        >
                          <Space size="small">
                            {!isMobile ? (
                              <>
                                <Tooltip
                                  title={
                                    !isMuteIndividual
                                      ? "Turn on Microphone"
                                      : "Turn off Microphone"
                                  }
                                  overlayClassName="tooltipAltyug"
                                  placement="topLeft"
                                >
                                  {index==0?(
                                     <div className="leftPanelItem popovermic" key={item.id}   onClick={() => {
                                    handleMute(
                                      !isMute
                                      );
                                    }}>
                                  {!isMute ? <FiMic/> :<FiMicOff/>}  
                                    </div>
                                  ):(
                                  <div className="leftPanelItem popovermic" key={item.id}   onClick={() => {
                                        handleMuteIndividual(
                                          item,
                                          item.isActive ? 0 : 100
                                        );
                                      }}>
                                    {item.isActive  ? <FiMic/> :<FiMicOff/>}  

                                    {/* <UseAnimations
                                      reverse={
                                        checkSpeaker(item.id)                 
                                      }
                                    
                                      strokeColor="white"
                                      size={20}
                                      wrapperStyle={{ cursor: "pointer" }}
                                      animation={microphone}
                                    /> */}
                                  </div> )}
                                  <div className="youText">

                                <small>
                                {item?.customerUserId == localStorage.getItem("userid") ? "you":""}
                                </small>
                                  </div>
                                </Tooltip>
                              </>
                            ) : (
                              ""
                            )}
                          </Space>
                        </Col>
                      </Row>
                    </List.Item>
                  )}
                />
              </>
            }
            overlayClassName="NavList"
          >
            <Col lg={2} md={2} sm={6} xs={8} className="logoArea avatargroup">
              <Avatar.Group
                maxCount={2}
                maxStyle={{
                  color: "#f56a00",
                  backgroundColor: "#fde3cf",
                }}
              >
                {peers &&
                  peers.map((item) => {
                   console.log(item,"item")
                    if (item?.metadata !== "") {
                      return (
                        <Avatar
                          src={JSON.parse(item?.metadata)?.image}
                          className="hp-cursor-pointer"
                        />
                      );
                    }
                  })}

              </Avatar.Group>
            </Col>
          </Popover>
        ) : (
          ""
        )}
        <Col lg={2} md={2} sm={6} xs={8} className="hp-text-center">
          <div className="leftPanelItemException navigationArea quicknav">
            <Popover
              content={
                <Row>
                  <Col span={24}>
                    <List
                      className="navigationAreaList"
                      itemLayout="horizontal"
                      dataSource={navigationArea}
                      renderItem={(item) => (
                        <List.Item>
                          <List.Item.Meta
                            avatar={
                              <Avatar
                                className="navigationAreaListAvatar"
                                src={item.image}
                              />
                            }
                            title={
                              <a
                                onClick={() =>
                                  handleQUickNavigation(item?.name)
                                }
                              >
                                {item?.name}
                              </a>
                            }
                          />
                        </List.Item>
                      )}
                    />
                  </Col>
                </Row>
              }
              overlayClassName="NavList"
            >
              <h6>Quick Navigation</h6>
            </Popover>
          </div>
        </Col>
        <Col lg={12} md={12} sm={6} xs={8} />
        <Col span={3} className="hp-text-center">
          <Space size="small">
            {/* <Tooltip title="Notifications" overlayClassName="tooltipAltyug" placement="bottom">
              <div className="leftPanelItemException notifs">
                <HeaderNotifications />

              </div>
            </Tooltip> */}
            <Tooltip
              title="Leaderboard"
              overlayClassName="tooltipAltyug"
              placement="bottom"
            >
              <div className="leftPanelItemException leaderboard">
                <RiBarChart2Fill
                  className="interactionIcons"
                  onClick={() => openStreamModule("LeaderBoard")}
                />
              </div>
            </Tooltip>
            <HeaderUser />
          </Space>
        </Col>
      </Row>

      <Row
        className="interactionLayerBottom"
        align="middle"
        justify="space-between"
      >
        <Col
          span={3}
          className="leftPanel text-center"
          style={{ marginLeft: "1vw" }}
        >
          <Space size="small">
            {!isMobile ? (
              <>
                <Tooltip
                  title={!isMute ? "Turn on Microphone" : "Turn off Microphone"}
                  overlayClassName="tooltipAltyug"
                  placement="topLeft"
                >
                  {console.log(isMute,"isMuteisMute")}
                  {/* <div className="leftPanelItem mic">
                    <UseAnimations
                      reverse={isMute}
                      onClick={() => {
                        handleMute(!isMute);
                      }}
                      strokeColor="white"
                      size={30}
                      wrapperStyle={{ cursor: "pointer" }}
                      animation={microphone}
                    />
                  </div> */}

<div className="leftPanelItemException mic"  onClick={() => {
                                    handleMute(
                                      !isMute
                                      );
                                    }}>
                                  {!isMute ? <FiMic  className="interactionIcons" /> :<FiMicOff className="interactionIcons"/>}  
                                    </div>                </Tooltip>

                <Tooltip
                  title={isMuteAll ? "Unmute Everyone" : "Mute Everyone"}
                  overlayClassName="tooltipAltyug"
                  placement="topLeft"
                >
                  <div className="leftPanelItemException silence">
                    {/* <UseAnimations
              reverse={isMuteAll}
              onClick={() => {
                handleMuteAll(!isMuteAll)
              }}
              strokeColor='white'
              size={30}
              wrapperStyle={{ cursor: "pointer" }}
              animation={volume}
            /> */}
                    {isMuteAll ? (
                      <RiVolumeMuteLine
                        className="interactionIcons"
                        onClick={() => {
                          handleMuteAll(!isMuteAll);
                        }}
                      />
                    ) : (
                      <RiVolumeUpLine
                        className="interactionIcons"
                        onClick={() => {
                          handleMuteAll(!isMuteAll);
                        }}
                      />
                    )}
                  </div>
                </Tooltip>
              </>
            ) : (
              ""
            )}

            <div className="leftPanelItemException reactions">
              <Popover
                content={
                  <>
                    <Row>
                      <Col span={24}>
                        <Space size="small">
                          <img
                            src={Hearts}
                            alt="Hearts"
                            width={26}
                            onClick={() => sendReaction("hearts")}
                            style={{ cursor: "pointer" }}
                          />
                          <img
                            src={Clapping}
                            alt="Clapping"
                            width={26}
                            onClick={() => sendReaction("clapping")}
                            style={{ cursor: "pointer" }}
                          />
                          <img
                            src={Laugh}
                            alt="Laugh"
                            width={26}
                            onClick={() => sendReaction("laugh")}
                            style={{ cursor: "pointer" }}
                          />
                          <img
                            src={Fire}
                            alt="Fire"
                            width={26}
                            onClick={() => sendReaction("fire")}
                            style={{ cursor: "pointer" }}
                          />
                        </Space>
                      </Col>
                      {/* <Col span={6}><img src={Hearts} alt="Hearts" width={40} /></Col>
                    <Col span={6}><img src={Hearts} alt="Hearts" width={40} /></Col>
                    <Col span={6}><img src={Hearts} alt="Hearts" width={40} /></Col>
                    <Col span={6}><img src={Hearts} alt="Hearts" width={40} /></Col> */}
                    </Row>
                  </>
                }
                overlayClassName="popoverAltyug"
              >
                <RiEmotion2Line className="interactionIcons" />
              </Popover>
            </div>
          </Space>
        </Col>
        <Col span={16} className="hp-text-center">
          {/* <Button type="primary" shape="circle" onClick={openEditor} icon={<Edit set="curved" className="remix-icon" />} /> */}
        </Col>

        <Col span={4} className="rightPanel">
          <Space size="large">
            <Tooltip title="Capture Photo" overlayClassName="tooltipAltyug">
              <RiCamera3Line
                className="interactionIcons capture"
                onClick={CaptureScreen}
              />
            </Tooltip>
            <Tooltip title="Change Avatar" overlayClassName="tooltipAltyug">
              <div className="hp-position-absolute" style={{ left: "19vh" }}>
                <Badge
                  count={unreadChatCount}
                  style={{ width: "15px", height: "15px" }}
                />
              </div>
              <RiBodyScanLine
                className="interactionIcons changeavatar"
                onClick={openAvatarCustomizer}
              />
            </Tooltip>
            <Tooltip title="Open Chat" overlayClassName="tooltipAltyug">
              <RiChat1Line
                className="interactionIcons livechat"
                onClick={() => openStreamModule("livechat")}
              />
            </Tooltip>

            {/* <Tooltip title="Open Poll" overlayClassName="tooltipAltyug">
              <RiChatPollLine className="interactionIcons polls" onClick={() => openStreamModule('Polling')} />
            </Tooltip> */}
            <Tooltip title="View Controls" overlayClassName="tooltipAltyug">
              <RiQuestionLine
                className="interactionIcons instructions"
                onClick={openInstructions}
              />
            </Tooltip>
          </Space>
        </Col>
      </Row>
      {isChatOpen && (
        <Row className="chatpanel">
          <LiveChat />
        </Row>
      )}

      {isPollOpen && (
        <Row className="chatpanelPoll">
          <PollingContent />

          <ChatPollingFooter />
        </Row>
      )}
      {isLeaderBoardOpen && <LeaderBoard />}
    </>
  );
}
