import React from 'react';
import {Row,Col,Carousel, Button} from 'antd'
import { useDispatch, useSelector } from "react-redux";
import actions from 'redux/virtual/altRealm/actions';
import "./productView.less"

export default function ProductView() {
  const requiredState = useSelector(({altRealm})=>({altRealm}))
  const dispatch = useDispatch();
  const {altRealm:{realms, currentRealm,interactionIndex}} = requiredState
  const theChosenRealm = realms[currentRealm]
  let theChosenProduct = ""
  if(interactionIndex !== "")
  {
    if(typeof theChosenRealm.products.data[interactionIndex] !== 'undefined') {
      theChosenProduct = theChosenRealm.products.data[interactionIndex]
    }
  }

  const imageShowcase = []
  // <div><h3>No Images</h3></div>
  if(theChosenProduct !== "")
  {
    theChosenProduct.images.forEach((element,index) => {
      imageShowcase.push(<div><img src={element} style={{width: "100%"}} alt={`${theChosenProduct.name}_${index}`} /></div>)
    });
  }
  return (
    theChosenProduct !== ""
    ?
      <>
        <Row>
          <Col span={24}>
            <Carousel autoplay>
              {imageShowcase}
            </Carousel>
          </Col>
        </Row>
        <br />
        <Row className='informationContainer'>
          <Col span={24}>
            <Row>
              <Col span={24}>
                <h3>{theChosenProduct.name}</h3>
              </Col>
            </Row>
            <br />
            <Row>
              <Col span={24}>
                <p>{theChosenProduct.description}</p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className='ctaButton'>
          <Col span={24}>
            <Button type="primary" onClick={() => {window.open(theChosenProduct.ctaLink,"_blank")}}>{theChosenProduct.ctaText}</Button>
          </Col>
        </Row>
      </>
    :
      <Row align="middle" justify="space-between" style={{height: "100vh"}}>
        <Col span={24}>
          <h3>No Details Found</h3>
        </Col>
      </Row>
  );
}
          