import React from 'react'
// import { FormattedMessage } from 'react-intl'
import {
  Home,
  TicketStar,
  Calendar,
  TwoUsers,
  Notification,
  Location,
  Chart
} from "react-iconly";

export async function getOrganizerMenu() {

  return [
    {
      id: "dashboard-page",
      // title: <FormattedMessage id="menu.dashboard" />,
      title: "Dashboard",
      icon: <Home set="broken" className="remix-icon" />,
      navLink: "/greenroom/dashboard",
      key: "greenroom"
    },
    {
        header: "SETUP",
    },
    {
      id: "event-setup",
      // title: <FormattedMessage id="menu.eventsetup" />,
      title: "Setup Game Realms",
      icon: <TicketStar set="broken" className="remix-icon" />,
      navLink: "/greenroom/setup-event",
      key: "eventsetup"
    },
    {
        id: "agenda-setup",
        // title: <FormattedMessage id="menu.agendasetup" />,
        title: "Agenda Setup",
        icon: <Calendar set="broken" className="remix-icon" />,
        navLink: "/greenroom/setup-agenda",
        key: "agendasetup"
    },
    {
        id: "speaker-setup",
        // title: <FormattedMessage id="menu.speakersetup" />,
        title: "Speaker Setup",
        icon: <TwoUsers set="broken" className="remix-icon" />,
        navLink: "/greenroom/setup-speaker",
        key: "speakersetup"
    },
    {
      header: "TOOLS",
    },
    {
        id: "live-notification",
        // title: <FormattedMessage id="menu.livenotification" />,
        title: "Live Notification",
        icon: <Notification set="broken" className="remix-icon" />,
        navLink: "/greenroom/live-notification",
        key: "livenotification"
    },
    {
        id: "follow-me",
        // title: <FormattedMessage id="menu.followme" />,
        title: "Follow Me Mode",
        icon: <Location set="broken" className="remix-icon" />,
        navLink: "/greenroom/follow-me",
        key: "followme"
    },
    {
        header: "ANALYTICS",
    },
    {
      id: "analytics-page",
      // title: <FormattedMessage id="menu.analytics" />,
      title: "Analytics",
      icon: <Chart set="broken"  className="remix-icon" />,
      navLink: "/greenroom/live-analytics",
      key: "analytics"
    },
  ]
}
