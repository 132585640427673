import React from 'react';
import {
  Row,
  Col
} from 'antd'
import "../../../editor.less"

export default function ItemIcon(props) {
  const {name,icon, theItemClick,theClassName} = props
  return (
    <Row onClick={theItemClick}>
      <Col span={4} />
      <Col span={16} className={theClassName}>
        <h5>
          <i className={icon} />
          <br />
          {name}
        </h5>
      </Col>
      <Col span={4} />
    </Row>
  );
}
          