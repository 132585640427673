import { all, takeEvery, call, put,select } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { message } from 'antd'
import actions from './actions'
import { useUnityContext } from "react-unity-webgl";
// import { getSavedData } from '../../../services/organizer/eventSetup'
// import menuActions from '../../common/menu/actions'


export function* GET_INIT() {
  try {
      const final = {
        "d306db40-ebd0-11ec-8ea0-0242ac120002": {
          "realmName" : "Altyug Space World",
          "realmDescription": "Altyug's first design. A space theme.",
          "codeUrl": "https://altyug.s3.ap-south-1.amazonaws.com/altyug.wasm", 
          "frameworkUrl": "https://altyug.s3.ap-south-1.amazonaws.com/altyug.framework.js", 
          "dataUrl": "https://altyug.s3.ap-south-1.amazonaws.com/altyug.data", 
          "loaderUrl": "https://altyug.s3.ap-south-1.amazonaws.com/altyug.loader.js"
        }
      }
      // }
      yield put({
        type: actions.SET_STATE,
        payload: {
          realms: final
        },
      })
      yield put(push(`/realm/${Object.keys(final)[0]}`))
    // }
  } catch (err) {
    console.log(err)
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_INIT, GET_INIT),


  ])
}