import actions from './actions'

const initialState = {
  id: '',
  name: '',
  firstName: '',
  lastName: '',
  roles: [],
  email: '',
  mode: 'email',
  authorized: false,
  loading: false,
  loginStatus: '',
  accessToken: '',
  tokenType: '',
  options: [],
  colors: ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae']
}

export default function theAdminReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}