import API from '../../configs/apiConfig'
import axios from 'axios'

export async function getSavedData() {
    return API.get('getAllRealmScenes')
}

export async function getRoles() {
    return API.get('getAllRoles')
}

export async function writeData(data) {
    console.log('Save the scene')
    console.log(data)
    return API.post('saveRealmScene', data.load, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

export async function getAvatarRender(data) {
  console.log('Render avatar')
  console.log(data)
  return axios.post('https://render.readyplayer.me/render', {
    model: data.avatar,
    scene: "fullbody-posture-v1-transparent",
    armature: "ArmatureTargetMale"
  }, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export async function deleteSection(data) {
  return API.get(`deleteClickSection/${data}`)
}  

export async function uploadFile(data) {
  const formData = new FormData();
  formData.append("file", data.file);
  return API.post('uploadFile', formData, data.config)
}


export async function getAgenda() {
  return API.get('getAllAgenda')
}

export async function getAllClues() {
  return API.get('getAllClues')
}

export async function getBackendAgenda() {
  return API.get('getAgenda')
}


export async function saveSpeakers(data) {
  console.log('Save the speakers')
  console.log(data)
  return API.post('saveSpeakers', data, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export async function getAllSpeakers() {
  return API.get('getSpeakers')
}


export async function writeAgenda(data) {
  return API.post('saveAgenda', data.load.agenda, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export async function writeClues(data) {
  return API.post('saveClues', data.load.clues, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export async function writeDefault(data) {
  const final = { id: data.load.id }
  return API.post('setDefaultScene', final, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export async function getDefault(data) {
  if (data.source !== undefined) {
    return API.get(`getDefaultScene/${data.source}`)
  }
  return API.get('getDefaultScene')
}

export async function getExhibitors() {
  return API.get('getAllExhibitors')
}

export async function getModerators() {
  return API.get('getAllModerators')
}

export async function saveMenu(data) {
  return API.post('saveNavigation', data.menu, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export async function saveNotification(data) {
  return API.post('saveNotifications', data.message, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export async function getMenu() {
  return API.get('getNavigation')
}

export async function getNotifications() {
  return API.get('getNotifications')
}

export async function deleteSession(data) {
  console.log('Calling delete Session')
  console.log(`deleteSession/${data}`)
  return API.get(`deleteSession/${data}`)
}
