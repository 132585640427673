const actions = {
  GET_INIT: 'eventSetup/GET_INIT',
  SET_STATE: 'eventSetup/SET_STATE',
  GET_DATA: 'eventSetup/GET_DATA',
  SAVE_DATA: 'eventSetup/SAVE_DATA',
  SET_SCENE: 'eventSetup/SET_SCENE',
  DELETE_CLICKSECTION: 'eventSetup/DELETE_CLICKSECTION',
  UPLOAD_FILE: 'eventSetup/UPLOAD_FILE'
}

export default actions
