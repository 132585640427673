import React, { useState } from "react";
import {Row,Col,Card} from 'antd'
import Iframe from "react-iframe";
import { useDispatch, useSelector } from "react-redux";
import actions from 'redux/virtual/altRealm/actions';
import GamingModal from "./GamingModal";

export default function ArcadeGames() {
  const requiredState = useSelector(({altRealm})=>({altRealm}))
  const {altRealm: {arcadeGameStep,arcadeGameURL}} = requiredState
  const dispatch = useDispatch();

  const {altRealm:{isInteractionDrawerVisible}} = requiredState
  const gameObject = {
    1: {
      gameIcon: "https://altyug.s3.ap-south-1.amazonaws.com/grizzy.png",
      gamename: "Grizzy & the Lemmings",
      gamedesc:
        "Help Grizzy get rid of as many lemmings as possible! Use your slingshot to catapult the lemmings into the moving boxes. There's a virtually inexhaustible supply of lemmings so see how many you can get in there within the time limit!",
      game_instn: "Use your mouse to aim and use slingshot to catapult the lemmings into the moving boxes.",
      gameurl: "https://wiz-grizzy-lemming-leaderboard.netlify.app/",
      pointsystem: "1",
      widgetID: "grizzy"
    },
    2: {
      gameIcon: "https://altyug.s3.ap-south-1.amazonaws.com/quiz.png",
      gamename: "Master Quiz",
      gamedesc:
        "It's time for a fun Quiz. Answer all questions correctly for points.",
      game_instn: "Solve the questions to move forward",
      gameurl: "https://form.jotform.com/230435504198455/",
      pointsystem: "1",
      widgetID: "quiz"
    },
    3: {
      gameIcon: "https://altyug.s3.ap-south-1.amazonaws.com/angryzombie.png",
      gamename: "Angry Zombies",
      gamedesc:
        "There can't be any left! In Angry Zombies you have to shoot down every zombie you meet. But be careful! Their villages are solid and your fire is limited. Be specific and don't take prisoners!",
      game_instn:
        "Mouse - Drag and Drop Skull. Kill All Zombies.",
      gameurl: "https://angry-zombies-leaderboard-new.netlify.app/",
      pointsystem: "1000",
      widgetID: "angry"
    },

  };

  const openGame = (gameData) => {
    dispatch({
      type: actions.SET_STATE,
      payload:{
        isInteractionDrawerVisible: false,
        isGamingModalOpen: true,
        gamingData: gameData
      }
    })
  }

  const showcase = Object.entries(gameObject).map((key) => {
    console.log("checking showcase")
    console.log(key)
    
    return(
      <>
      <Col span={1} />
      <Col span={22}>
        <Card bordered={false} className="hp-text-center" style={{ width: "100%", cursor: "pointer" }} onClick={() => {openGame(key[1])}}>
          <img src={key[1].gameIcon} style={{width: "100%"}} />
          <br />
          <h3>{key[1].gamename}</h3>
        </Card>
      </Col>
      <Col span={1} />
      </>
    )
  })
  return (
    <>
    <GamingModal />
    <br />
    <Row align="middle" justify="space-between">
      <Col span={24} className="hp-text-center">
          <h3>ARCADE GAMES</h3>
      </Col>
    </Row>
    <Row align="middle" justify="space-between">
      {showcase}
    </Row>
    </>
  );
}
          