import { combineReducers } from "redux";
import { connectRouter } from 'connected-react-router'

import customiseReducer from "./customise/customiseReducer";
import virtualUser from "./virtual/user/reducers";
import registerUser from "./virtual/registerUser/reducers"
import altRealm from './virtual/altRealm/reducers'
import gameContext from './virtual/gameContext/reducers'
import realmEditor from './virtual/realmEditor/reducers'

import organizer from './organizer/theorganizer/reducers'
import registerOrganizer from './organizer/registerOrganizer/reducers'
import eventSetup from './organizer/eventSetup/reducers'

import theAdmin from './admin/theAdmin/reducers'

import menu from './common/menu/reducers'
import commonsettings from './common/settings/reducers'
import organizerSetup from './organizer/agenda/reducers'
import Chat from "redux/virtual/chat/reducers";
import streamSession from "./virtual/streamSession/reducers";
import stageSetup from "./organizer/stageSetup/reducers";
import rtcFramework from './virtual/rtcFramework/reducers'
const rootReducer = (history) => combineReducers({
  router: connectRouter(history),
  customise: customiseReducer,
  virtualUser: virtualUser,
  registerVirtualUser: registerUser,
  altRealm,
  realmEditor,
  gameContext,
  organizer,
  registerOrganizer,
  eventSetup,
  theAdmin,
  menu,
  commonsettings,
  organizerSetup,
  Chat,
  streamSession,
  stageSetup,
  rtcFramework
});

export default rootReducer;