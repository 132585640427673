import API from '../../configs/apiConfig'

export async function uploadFile(data) {
  const formData = new FormData();
  formData.append("file", data.file);
  return API.post('uploadFile', formData, data.config, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
}

export async function uploadBase64Image(data) {
  return API.post('uploadImage', data)
}

export async function uploadBase64ImageforBackgroundRemoval(data) {
  // console.log("base64 string")
  // console.log(data)
  const formData = new FormData();
  formData.append("file", data.imageData);
  // return API.post('removeBackground', data)
  return API.post('removeBackground', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export async function uploadBase64ImageforToonify(data) {
  // console.log("base64 string")
  // console.log(data)
  const formData = new FormData();
  formData.append("file", data.imageData);
  // return API.post('removeBackground', data)
  return API.post('createCaricature', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
}

export async function uploadPhotoboothImage(data) {
  // console.log("base64 string")
  // console.log(data)
  const formData = new FormData();
  formData.append("file", data.imageData);
  // return API.post('removeBackground', data)
  return API.post('uploadUserPhotobooth', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${data.token}`
    }
  })
}

export async function getAllPhotoboothUploads() {
  return API.get('getAllPhotoboothUploads')
}