import React from 'react';
import {
  Row,
  Col,
  Button,
} from 'antd'
import { useDispatch, useSelector } from "react-redux";
import actions from 'redux/virtual/realmEditor/actions';
import Scrollbars from "react-custom-scrollbars";
import EditorLogo from "assets/images/icons/editor.png"
import "../editor.less"
// import categories from "./editor.json"
import Sidebar from './Sidebar';
import Header from './Header';
import Footer from './Footer';
import MainContent from './MainContent';

export default function TheEditor() {
  const dispatch = useDispatch();
  const requiredState = useSelector(({realmEditor,altRealm})=>({realmEditor,altRealm}))
  const {realmEditor:{currentEditorScreen,categories}, altRealm: {realms, currentRealm}} = requiredState
  const selectedRealm = realms[currentRealm]
  const startEditor  = () => {
    dispatch({
      type: actions.SET_STATE,
      payload: {
        currentStep: 1,
      },
    })
  }

  let theChosenCategory = ""
  let theCategoryInfo = ""
  if(selectedRealm.hasOwnProperty(currentEditorScreen))
  {
    theCategoryInfo = categories[currentEditorScreen]
    theChosenCategory = selectedRealm[currentEditorScreen]
  }

  return (
    <Row justify="space-around" className="hp-text-center">
      <Col span={4}>
        <Sidebar options={categories} />
      </Col>
      <Col span={20}>
        {theChosenCategory !== "" ?
          <Row justify="space-around" align="bottom" className="hp-text-left theHeader">
            <Header theChosenCategory={theCategoryInfo} currentEditorScreen={currentEditorScreen} />
          </Row>
        :
        ""
        }
        <Row justify="space-around" align="middle" className="hp-text-center">
          <Col span={24}>
            <Scrollbars autoHeight autoHeightMin="100px" autoHeightMax="72vh">
              <MainContent theCategoryInfo={theCategoryInfo} theChosenCategory={theChosenCategory} currentEditorScreen={currentEditorScreen} />
            </Scrollbars>
          </Col>
        </Row>
        {theChosenCategory !== "" && theChosenCategory.data.length !== 0 ?
        <Row justify="space-around" align="bottom" className="hp-text-right theFooter">
          <Col span={24}>
            <Footer options={categories} />
          </Col>
        </Row>
        :
        ""
      }
      </Col>
    </Row>
  );
}
          