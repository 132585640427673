import actions from './actions'

const initialState = {
  isRealmEditorOpen: false,
  currentStep: 0,
  currentEditorScreen: "",
  isSavingChanges: false,
  categories: {
    "products": {
      "name" : "Product", 
      "icon": "ri-gift-2-fill", 
      "subtitle": "Add 3D products that users can experience",
      "slots": 5,
      "data": [],
      "initialDataItem": {
        "uuid": "",
        "url": "",
        "scale": 1,
        "rotation": 0,
        "name": "",
        "description": "",
        "images": [],
        "ctaText": "Buy Now",
        "ctaLink": ""
      }
    },
    "videos": {
      "name" : "Video", 
      "icon": "ri-video-upload-fill",
      "subtitle": "Run Videos or Stream Content",
      "slots": 3,
      "data": [],
      "initialDataItem": {
        "uuid": "",
        "url": "",
        "name": "",
        "isMuted": false,
        "showControls": true,
        "loop": true,
        "type": "hls"
      }
    },
    "images": {
      "name" : "Image", 
      "icon": "ri-image-add-fill",
      "subtitle": "Create instant art gallery with Images",
      "slots": 2,
      "data": [],
      "initialDataItem": {
        "uuid": "",
        "url": "",
        "name": "",
        "description": "",
      }
    },
    "iframes": {
      "name" : "Iframe", 
      "icon": "ri-code-s-slash-fill",
      "subtitle": "Add a 3rd Party extension with iFrame plug",
      "slots": 0,
      "data": [],
      "initialDataItem": {
        "uuid": "",
        "url": "",
        "name": "",
      }
    }
  }
}

export default function virtualuserReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    // case actions.SET_DATA:
    //   return {
    //     ...state,
    //     categories: {
    //       ...state.categories,
    //       [action.payload.currentEditorScreen]: {
    //         ...state.categories[action.payload.currentEditorScreen],
    //         data: action.payload.actualload,
    //       },
    //     },
    //   }
    // case actions.SET_INSIDE_DATA:
    //   return {
    //     ...state,
    //     categories: {
    //       ...state.categories,
    //       [action.payload.currentEditorScreen]: {
    //         ...state.categories[action.payload.currentEditorScreen],
    //         data[action.payload.theIndex]: [
    //           ...state.categories[action.payload.currentEditorScreen].data,
    //           : action.payload.actualload,
    //         ]
            
    //       },
    //     },
    //   }
    default:
      return state
  }
}