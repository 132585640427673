import React,{useEffect} from "react";
import { Link } from "react-router-dom";

import { Dropdown, Col, Avatar, Divider, Row } from "antd";
import { Calendar, Game, People, } from "react-iconly";
import { useSelector,useDispatch } from "react-redux";
import actions from "redux/virtual/altRealm/actions"
import { push } from 'connected-react-router'

import avatarImg from "../../../assets/images/memoji/memoji-1.png";
import Analytic from 'configs/analytic'
import mixpanel from "mixpanel-browser";

export default function HeaderUser() {
  const requiredState = useSelector(({altRealm,virtualUser})=>({altRealm,virtualUser}))
  const dispatch = useDispatch();
  const {altRealm: {realms,avatarID,ProfileImage}} = requiredState
  const changeRealm = (realmID) => {
    dispatch(push(`/realm/changingrealm`))
    setTimeout(() => {
      dispatch({
        type: actions.SET_STATE,
        payload:{
          currentRealm: realmID
        }
      })
      dispatch(push(`/realm/${realmID}`))
    }, 2000);
    
  }

  const logout = () => {

    const analyticData = {
      key: "User Logged Out",

    }
    Analytic(analyticData)
    localStorage.removeItem("userid");
    localStorage.removeItem("acctoken");
    localStorage.removeItem("tokenType");
    localStorage.removeItem("userData");
    mixpanel.reset()
    setTimeout(() => {
      window.location.replace("/");
    }, 1000);
  };
  const allRealms = Object.entries(realms).map((key) => {
    return (
      <Col span={24}>
        <p  onClick={() => changeRealm(key[0])} className="hp-d-flex-center hp-p1-body hp-py-8 hp-px-10 hp-d-block hp-transition hp-hover-bg-primary-4 hp-hover-bg-dark-80 hp-border-radius" style={{cursor:"pointer", marginLeft: -10, marginRight: -10 }}>
          <span className="hp-ml-8">{key[1].realmName}</span>
        </p>
      </Col>
    )
  })
  const menu = (
    <div className="hp-border-radius hp-border-1 hp-border-color-black-40 hp-bg-black-0 hp-bg-dark-100 hp-border-color-dark-80 hp-p-24 hp-mt-12" style={{ width: 260 }}>
      {/* <span className="hp-d-block h5 hp-text-color-black-100 hp-text-color-dark-0 hp-mb-8">Change Realm</span> */}

      {/* <Row>
        {allRealms}
      </Row>

      <Divider className="hp-mb-16 hp-mt-6" /> */}

      <a  onClick={logout}  className="hp-p1-body">
        Logout
      </a >
    </div>
  );

  const theProfileImage = avatarID === null ? avatarImg : `https://api.readyplayer.me/v1/avatars/${avatarID}.png?scene=fullbody-portrait-v1`
  useEffect(() => {
    if(theProfileImage !==null){

      dispatch({
        type: actions.SET_STATE,
        payload:{
          ProfileImage: theProfileImage
        }
      })
    }
  }, [theProfileImage])
  
 

  return (
    <Col>
      <Dropdown overlay={menu} placement="bottomLeft">
        <Avatar src={theProfileImage} size={50} className="hp-cursor-pointer theAvaatr" />
      </Dropdown>
    </Col>
  );
};
