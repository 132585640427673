import { all, put, call, takeEvery,select } from 'redux-saga/effects'
import { getEventMenu } from '../../../services/virtual/menu'
import { getOrganizerMenu } from '../../../services/organizer/menu'
import { getAdminMenu } from '../../../services/admin/menu'
import actions from './actions'

export function* GET_DATA() {
  const state = yield select()
  const {
    commonsettings: { currentArena },
    altRealm: {scenes}
  } = state
  let menuData = []
  if(currentArena === "event" || currentArena === "userlogin") { console.log("fetching event menu"); menuData = yield call(getEventMenu,scenes) }
  else if(currentArena === "greenroom" || currentArena === "organizerlogin") { menuData = yield call(getOrganizerMenu) }
  else if(currentArena === "adminpanel" || currentArena === "adminlogin" ) { menuData = yield call(getAdminMenu) }
  // console.log("here we are fetching the menu")
  // console.log(menuData)
  yield put({
    type: actions.SET_STATE,
    payload: {
      menuData
    },
  })
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_DATA, GET_DATA)])
}
