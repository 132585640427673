import React from 'react'
import { Form, Input, Button, Space } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import uuid from 'react-uuid'
import actions from 'redux/virtual/streamSession/actions'
import {useDispatch, useSelector } from 'react-redux'
// import styles from '../style.module.scss'

/* eslint-disable prefer-promise-reject-errors, no-plusplus, consistent-return,react/destructuring-assignment */
const { TextArea } = Input


function PollingForm(props) {
  const requiredState = useSelector(({ virtualUser, eventApp, Chat,streamSession }) => ({ virtualUser, eventApp, Chat,streamSession }))

  const dispatch = useDispatch()
  const onSubmit = values => {
    const {
      virtualUser: { id, name, avatarType, avatarColor, avatarData },
    } = requiredState

    const optionsSerialized = {}
    let counter = 1
    values.options.forEach(element => {
      const newID = uuid()
      optionsSerialized[newID] = {
        id: newID,
        option: element.option,
        votes: 0,
        position: counter++
      }
    })
    const messageFinal = {
      message_id: uuid(),
      type: 'polling',
      moderationStatus: 'approved',
      publisher: id,
      question: values.question,
      options: optionsSerialized,
      answered: false,
      title: name,
      avatar:
        avatarType === 'text'
          ? `https://via.placeholder.com/50x50.png/${avatarColor.substring(
              1,
            )}/fff?text=${avatarData}`
          : avatarData,
    }

    dispatch({
      type: actions.SEND_CREATE_POLL,
      payload: {
        message: messageFinal,
      },
    })

    props.closePoll()
  }

    return (
      <div>
        <Form
          name="pollingform"
          onFinish={onSubmit}
          scrollToFirstError
          layout="vertical"
          hideRequiredMark
        >
          <Form.Item label="Question" name="question">
            <TextArea
              placeholder="Enter your Poll Question here."
              autoSize={{ minRows: 3, maxRows: 4 }}
            />
          </Form.Item>
          <br />
          <Form.Item label="Options">
            <Form.List name="options">
              {(fields, { add, remove }) => {
                return (
                  <div>
                    {fields.map(field => (
                      <Space
                        key={field.key}
                        style={{ display: 'flex', marginBottom: 8 }}
                        align="start"
                      >
                        <Form.Item
                          {...field}
                          name={[field.name, 'option']}
                          fieldKey={[field.fieldKey, 'option']}
                          rules={[{ required: true, message: 'Missing the option' }]}
                        >
                          <Input placeholder={`Option ${field.key + 1}`} />
                        </Form.Item>

                        <MinusCircleOutlined
                          onClick={() => {
                            remove(field.name)
                          }}
                        />
                      </Space>
                    ))}

                    {fields.length <= 5 ? (
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => {
                            add()
                          }}
                          block
                        >
                          <PlusOutlined /> Add Options
                        </Button>
                      </Form.Item>
                    ) : (
                      ''
                    )}
                  </div>
                )
              }}
            </Form.List>
          </Form.Item>
          {/* <Form.Item name="mcq" valuePropName="checked">
            <Checkbox>Have Multiple Answers</Checkbox>
          </Form.Item> */}

          <div className="form-actions">
            <Form.Item>
              <Button type="primary" className="width-150 mr-4" htmlType="submit">
                Publish Poll
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    )
  }


export default PollingForm
