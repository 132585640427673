import React from 'react';
import {
  Row,
  Col,
  Button,
} from 'antd'
import { useDispatch, useSelector } from "react-redux";
import actions from 'redux/virtual/realmEditor/actions';
import "../../editor.less"

export default function Header(props) {
  const dispatch = useDispatch();
  const requiredState = useSelector(({realmEditor})=>({realmEditor}))
  const {realmEditor:{currentEditorScreen,isSavingChanges}} = requiredState
  const {options} = props
  const saveChanges  = () => {
    dispatch({
      type: actions.SAVE_CHANGES
    })
  }
  let theHeader = ""
  let theSubtitle = ""
  if(options.hasOwnProperty(currentEditorScreen))
  {
    theHeader = options[currentEditorScreen].name
    theSubtitle = options[currentEditorScreen].subtitle
  }
  return (
    <div>
      <Button type='primary' loading={isSavingChanges} onClick={saveChanges}>Save Changes</Button>
    </div>
  );
}
          