import React from 'react'
import { Row, Col, Avatar } from 'antd'
import { useDispatch, useSelector } from "react-redux";
import actions from 'redux/virtual/streamSession/actions'
import './chatcomponent.less'
import { log } from '@craco/craco/lib/logger';

export default function ChatComponent(props) {

  const { data } = props
  const dispatch = useDispatch();
  console.log(data,"dataavatar");
  const requiredState = useSelector(({virtualUser})=>({virtualUser}))
    return (
      <div className={data.position === "right" ? "thechatcomponentlight" : "thechatcomponent"}>
        <Avatar src={data.avatar} size={40} style={{float: "left", margin: "-5px 0 0 -20px"}}/>
        <Row>
          <Col span={1} />
          <Col span={23}>
              <p className="texttitle">{data.title}</p>
              <p className="textbody">{data.text}</p>
          </Col>
        </Row>
      </div>
    )
  }
