import actions from './actions'

const initialState = {
  updateSelfPosition:null,
  updatePosition:null,
  isMuteAll:false,
  isMute:true,
  isMuteIndividual:false,
  peerDetails:[],
  zg: null,
  localStream: null,
  isChatOpen: false,
  isPollOpen:false
  
}

export default function rtcFrameworkReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}