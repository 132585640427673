import React from 'react';
import {
  Row,
  Col,
  Button,
} from 'antd'
import { useDispatch, useSelector } from "react-redux";
import actions from 'redux/virtual/altRealm/actions';
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import ProcessingImage from "assets/images/processingloading.gif"
import EditorLogo from "assets/images/icons/editor.png"
import "../photobooth.less"

export default function ThePhotobooth() {
  const requiredState = useSelector(({altRealm})=>({altRealm}))
  const {altRealm: {imageProcessing,photoboothUrl,currentbackground}} = requiredState
  const dispatch = useDispatch();

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  }
  
  const backgrounds = {
    1: {
      imagePath: 'assets/images/photobooth/bg1.jpg',
      thumbnail: 'assets/images/photobooth/bg1.jpg',
    },
    // 2: {
    //   imagePath: 'resources/images/backgrounds/background2.jpg',
    //   thumbnail: 'resources/images/backgrounds/background2.jpg',
    // },
    // 3: {
    //   imagePath: 'resources/images/backgrounds/background3.jpg',
    //   thumbnail: 'resources/images/backgrounds/background3.jpg',
    // },
    // 4: {
    //   imagePath: 'resources/images/backgrounds/background4.jpg',
    //   thumbnail: 'resources/images/backgrounds/background4.jpg',
    // },
    // 5: {
    //   imagePath: 'resources/images/backgrounds/background5.jpg',
    //   thumbnail: 'resources/images/backgrounds/background5.jpg',
    // },
    // 6: {
    //   imagePath: 'resources/images/backgrounds/background6.jpg',
    //   thumbnail: 'resources/images/backgrounds/background6.jpg',
    // },
    // 7: {
    //   imagePath: 'resources/images/backgrounds/background7.jpg',
    //   thumbnail: 'resources/images/backgrounds/background7.jpg',
    // },
  }

  const goNext = () => {
    const canvas = document.createElement('canvas')
    canvas.id = 'backgroundImage'
    canvas.width = 1000
    canvas.height = 562
    const ctx = canvas.getContext('2d')

    const backgroundImage = new Image()
    backgroundImage.src = backgrounds[currentbackground].imagePath
    ctx.drawImage(backgroundImage, 0, 0)

    const image = new Image()
    image.setAttribute('crossorigin', 'anonymous');
    image.src = transparentImage
    image.onload = () => {
      ctx.drawImage(image, 0, 0)
      const finalImage = canvas.toDataURL()
      dispatch({
        type: actions.SET_STATE,
        payload: {
          imageSrc: finalImage,
        },
      })

      const imgBlob = dataURLtoFile(finalImage,`${Date.now()}photobooth.png`)
      dispatch({
        type: actions.UPLOAD_PHOTOBOOTH_IMAGE,
        payload: {
          imageString: imgBlob
        },
      })
    }
    dispatch({
      type: actions.SET_STATE,
      payload: {
        currentStep: currentStep + 1,
      },
    })
  }

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8arr], filename, {type:mime});
  }

  const retake = () => {
    const {
      dispatch,
      aiphotobooth: { currentStep },
    } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        currentStep: currentStep - 1,
        imageSrc: '',
      },
    })
  }

  // const changeBackground = () => {
  //   dispatch({
  //     type: actions.SET_STATE,
  //     payload: {
  //       currentbackground: 
  //     },
  //   })
  // }

  return (
    <>
    {/* <Row justify="space-around" align="middle" className="text-center">
      <Col span={24} className="text-center"> */}
    {imageProcessing ? <div className="loaderOverlay"><img src={ProcessingImage} alt="loader" style={{ width: '100%', opacity: "0.8" }} /></div> : ''}
    <img
      src={backgrounds[currentbackground].imagePath}
      alt="backgroundImage"
      style={{ width: '100%' }}
    />
    {imageProcessing ? '' : <img src={photoboothUrl} alt="newImage" style={{ width: '100%' }} className="overlayimage" />}
    <Row justify="space-around" align="middle" style={{ marginTop: '5px' }}>
      <Col span={8} className="text-center" />
      <Col span={8} className="text-center">
        <Button type="primary" onClick={goNext} size="medium">
            Next &nbsp; <RightOutlined />
        </Button>
      </Col>
      <Col span={8} className="text-center" />
    </Row>
    {/* <Row justify="space-around" align="middle" style={{ marginTop: '5px' }}>
      <Col span={24} className="containerSlider">
        <h4 style={{color: "white"}}>Select Background</h4>
      </Col>
      <Col span={9} />
      <Col span={6}>
        <img src='assets/images/photobooth/bg1.jpg' style={{width: "100%"}} />
      </Col>
      <Col span={9} />

    </Row> */}
  </>
  );
}
          