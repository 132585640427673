import React from 'react'
import { all, put, call, takeEvery, select } from 'redux-saga/effects'
import {
  // getallUsers,
  initChat,
  saveChat,
  pubnubPublish,
  pubnubSubscribe,
  getChannels,
  pubnubFetchMessages,
  pubnubUnSubscribe,
  // getLeads,
  // searchUsers
} from 'services/virtual/chat'
// import { getAgenda } from 'services/virtual/setup'
import { SystemMessage } from 'react-chat-elements'
import { message, notification } from 'antd'
// import MeetingInvite from 'components/LayoutComponents/Virtual/Chat/LayoutComponent/MeetingInvite'
// import VisitingCard from 'components/LayoutComponents/Virtual/Chat/LayoutComponent/VisitingCard'
import uuid from 'react-uuid'
import store from '../../store'
// import eventActions from 'redux/virtual/event/actions'
import actions from './actions'
import pub from 'configs/organizerConfig'


// export function* GET_ALL_USERS() {
//   try {
//     const state = yield select()
//     const {
//       virtualUser: { accessToken },
//       Chat: {maxUsers}
//     } = state
//     const allUsersData = yield call(getallUsers, {token: accessToken, maxUsers, currentPagination:0})
//     if (allUsersData.status === 200) {
//       yield put({
//         type: actions.SET_STATE,
//         payload: {
//           allUsers: allUsersData.data,
//           initloadingAttendeeList: false,
//           currentPagination: 1,
//           isSearchResult: false,
//           searchPagination: 0
//         },
//       })
//     } else {
//       message.error('Cannot Fetch users for Chat. Try Logging in again.')
//     }
//   } catch (err) {
//     message.error(`${err.response.status}:${err.response.data.message}`)
//   }
// }

// export function* LOAD_MORE_USERS() {
//   try {
//     const state = yield select()
//     const {
//       virtualUser: { accessToken },
//       Chat: {maxUsers, currentPagination,allUsers,searchPagination,isSearchResult,searchTerm}
//     } = state
//     yield put({
//       type: actions.SET_STATE,
//       payload: {
//         loadingAttendeeList: true
//       },
//     })

//     const allUsersData = isSearchResult ? yield call(searchUsers, {token: accessToken, maxUsers,searchkey: searchTerm, currentPagination:searchPagination}) : yield call(getallUsers, {token: accessToken, maxUsers, currentPagination})
//     if (allUsersData.status === 200) {
//       yield put({
//         type: actions.SET_STATE,
//         payload: {
//           allUsers: { ...allUsers , ...allUsersData.data},
//           loadingAttendeeList: false,
//           currentPagination: isSearchResult ? 0 : currentPagination + 1,
//           searchPagination: isSearchResult ? searchPagination + 1 : 0
//         },
//       })
//     } else {
//       message.error('Cannot Fetch users for Chat. Try Logging in again.')
//     }
//   } catch (err) {
//     message.error(`${err.response.status}:${err.response.data.message}`)
//   }
// }

// export function* SEARCH_USER(data) {
//   try {
//     const state = yield select()
//     const {
//       virtualUser: { accessToken },
//       Chat: {maxUsers}
//     } = state
//     yield put({
//       type: actions.SET_STATE,
//       payload: {
//         loadingAttendeeList: true
//       },
//     })
//     const searchedUsersData = yield call(searchUsers, {token: accessToken, searchkey: data.payload.searchkey, maxUsers, currentPagination: 0})
//     if (searchedUsersData.status === 200) {

//       yield put({
//         type: actions.SET_STATE,
//         payload: {
//           allUsers: searchedUsersData.data,
//           loadingAttendeeList: false,
//           isSearchResult: true,
//           searchTerm: data.payload.searchkey,
//           searchPagination: 1
//         },
//       })
//     } else {
//       message.error('Cannot Fetch users for Chat. Try Logging in again.')
//     }
//   } catch (err) {
//     message.error(`${err.response.status}:${err.response.data.message}`)
//   }
// }


export function* INIT_CHAT() {
  try {
    const state = yield select()
    const {
      virtualUser: { id },
    } = state
    const pubnub = yield call(initChat, id)
    yield put({
      type: actions.SET_STATE,
      payload: {
        pubnub,
      },
    })
    // yield put({
    //   type: actions.GET_CHANNELS,
    // })
    // yield put({
    //   type: 'messagebox/FETCH_FEEDBACK'
    // })
    yield put({
      type: actions.GET_CHANNELS,
    })
  } catch (err) {
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* SEND_MESSAGE(data) {
  try {
    const state = yield select()
    const {
      Chat: { pubnub },
      streamSession: { selectedStage },
    } = state

    console.log('checking selectedStage')
    console.log(selectedStage)
    const idToSend = selectedStage
    /* Modification For Flipkart */
    // const idToSend = Object.keys(agendas).length !== 0 ? Object.keys(agendas)[0] : Object.keys(currenSceneAgenda)[0]
    const publishData = {
      channelID: idToSend,
      message: data.payload.message,
      pubnub,
    }

    const result = yield call(pubnubPublish, publishData)
    const utcSeconds = Math.round(result.timetoken / 10000000)
    const d = new Date(0) // The 0 there is the key, which sets the date to the epoch
    d.setUTCSeconds(utcSeconds)

    const toAdd = {
      position: 'right',
      type: 'text',
      category: data.payload.message.type,
      text: data.payload.message.content,
      date: d,
      status: 'recieved',
      title: data.payload.message.title,
      avatar: data.payload.message.avatar,
      notch: false,
      replyButton: true,
    }

    if (Object.keys(data.payload.message.reply).length > 0) {
      toAdd.reply = data.payload.message.reply
    }

    yield put({
      type: actions.SET_MESSAGE,
      payload: {
        key: 'chat',
        countType: 'unreadChatCount',
        count: 0,
        toAdd,
      },
    })
  } catch (err) {
    console.log(err.status)
    console.log(err)
    console.log(err.reponse)
    yield put({
      type: actions.SET_MESSAGE,
      payload: {
        key: 'chat',
        countType: 'unreadChatCount',
        count: 0,
        toAdd: {
          position: 'right',
          type: 'text',
          category: data.payload.message.type,
          text: data.payload.message.content,
          status: 'warning',
          title: data.payload.message.title,
          avatar: data.payload.message.avatar,
          notch: false,
        },
      },
    })
  }
}





export function* CREATE_CHANNEL(data) {
  try {
    const generatedChannelName = uuid()
    const state = yield select()
    const {
      virtualUser: {
        accessToken,
        id,
        name,
        organization,
        designation,
        avatarType,
        avatarData,
        avatarColor,
      },
      Chat: { allUsers, userType, visitedExhibitors },
    } = state

    console.log('data', data)
    const user = 'attendee'
    // userType === 'attendee'
    //   ? allUsers[data.payload.chatWith]
    //   : visitedExhibitors[data.payload.chatWith]
    const ChannelType = userType === 'attendee' ? 'attendee' : 'business'
    yield call(pubnubPublish, {
      pubnub: data.payload.pubnub,
      channelID: data.payload.chatWith,
      message: {
        type: 'subscribe',
        subscribeTo: generatedChannelName,
        ChannelType,
        publisher: id,
        assignedTo: {},
        requestFor: data.payload.chatWith,
        userMeta: {
          name,
          organization,
          designation,
          avatar_type: avatarType,
          avatar_data: avatarData,
          avatar_color: avatarColor,
          login_status: true,
        },
      },
    })
    const apiresult = yield call(saveChat, {
      ChannelName: generatedChannelName,
      ChannelType,
      token: accessToken,
      ChatWith: data.payload.chatWith,
      assignedTo: {},
    })
    if (apiresult.status === 200) {
      yield put({
        type: actions.SUBSCRIBE_CHANNELS,
        payload: {
          channels: [generatedChannelName],
          pubnub: data.payload.pubnub,
        },
      })

      yield put({
        type: actions.ADD_CHANNEL,
        payload: {
          actualload: {
            channelID: generatedChannelName,
            channelType: 'private',
            chatWith: data.payload.chatWith,
            messages: [],
            lastTime: 631132200,
            userMeta: {
              name: user.name,
              avatar_type: user.avatar_type,
              avatar_data: user.avatar_data,
              avatar_color: user.avatar_color,
              login_status: true,
            },
          },
        },
      })
      yield put({
        type: actions.SEND_MESSAGE,
        payload: {
          pubnub: data.payload.pubnub,
          message: data.payload.message,
          chatWith: data.payload.chatWith,
        },
      })
    }
  } catch (err) {
    console.log(err.response)
    console.log(err.status)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}


export function* SUBSCRIBE_CHANNELS(data) {
  try {
    console.log(pubnubSubscribe, data.payload,"pubnubSubscribe, data.payload");
    const subsResult = yield call(pubnubSubscribe, data.payload)
    console.log('response of subscription')
    console.log(subsResult)
  } catch (err) {
    console.log(err.status)
  }
}

export function* GET_CHANNELS() {
  try {
    const state = yield select()
    const {
      virtualUser: { accessToken, id, roles, assignedScene },
      Chat: { pubnub },
    } = state
    // const result = yield call(getChannels, accessToken)
    // console.log("getchannel",result)
    const channels = []
    // if (Object.keys(result.data).length > 0) {
      
    //   const channelDataAppended = {}
    //   Object.entries(result.data).map(key => {
    //     channels.push(key[1].channelID)
    //     channelDataAppended[key[0]] = {
    //       channelID: key[1].channelID,
    //       chatWith: key[1].chatWith,
    //       lastTime: 631132200,
    //       messages: [],
    //       readStatus: 'read',
    //       unreadCount: 0,
    //       channelType: key[1].channelType,
    //       userMeta: key[1].userMeta,
    //       parent:
    //         roles.includes('ROLE_EXHIBITOR') && key[1].channelType === 'business'
    //           ? key[1].parent
    //           : null,
    //       assignedTo: Object.prototype.hasOwnProperty.call(key[1], 'assignedTo')
    //         ? key[1].assignedTo
    //         : {},
    //     }
    //     return ''
    //   })

    //   yield put({
    //     type: actions.SET_STATE,
    //     payload: {
    //       channelData: channelDataAppended,
    //       currentTab: 'messages',
    //     },
    //   })

    //   yield put({
    //     type: actions.FETCH_MESSAGES,
    //     payload: {
    //       channels,
    //       pubnub,
    //     },
    //   })
      
    // }
    channels.push(id)
    // if (roles.includes('ROLE_EXHIBITOR')) {
    //   assignedScene.forEach(element => {
    //     channels.push(element)
    //   })
    //   yield put({
    //     type: actions.FETCH_REQUESTS,
    //     payload: {
    //       channels: assignedScene,
    //       pubnub,
    //     },
    //   })

    //   yield put({
    //     type: actions.FETCH_LEADS,
    //     payload: {
    //       theScene: assignedScene,
    //     },
    //   })
    // }
    channels.push(process.env.REACT_APP_NOTIFICATION_CHANNEL)
    // channels.push(process.env.REACT_APP_MESSAGEBOX_CHANNEL)
    yield put({
      type: actions.SUBSCRIBE_CHANNELS,
      payload: {
        channels,
        pubnub,
      },
    })
    console.log("check subscriptions")
    console.log(channels)
  } catch (err) {
    console.log('GET CHANNEL ERROR')
    console.log(err)
    console.log(err.response)
  }
}

export function* FETCH_MESSAGES(data) {
  try {
    const state = yield select()
    const {
      Chat: { channelData },
      virtualUser: { id },
    } = state
    const fetchResult = yield call(pubnubFetchMessages, data.payload)
console.log(fetchResult,"fetchResult")
    Object.entries(fetchResult.channels).map(key => {
      let selectedChannel = ''

      Object.entries(channelData).map(item => {
        if (item[1].channelID === key[0]) {
          selectedChannel = item[1].chatWith
          return item[1].chatWith
        }
        return ''
      })
      const messages = []
      let lastTime = 631132200
      key[1].forEach(obj => {
        const utcSeconds = Math.round(obj.timetoken / 10000000)
        const d = new Date(0) // The 0 there is the key, which sets the date to the epoch
        d.setUTCSeconds(utcSeconds)
        let actualText = obj.message.content
        if (obj.message.type === 'videomeet') {
          actualText = <MeetingInvite id={obj.message.content.props.id} />
        }
        if (obj.message.type === 'visitingcard') {
          actualText = <VisitingCard userData={obj.message.content.props.userData} />
        }
        if (obj.message.type === 'assignExhibitor') {
          actualText = (
            <SystemMessage text={`${obj.message.assignedTo.name} has been assigned to you.`} />
          )
        }

        let position = 'left'
        if (obj.message.publisher === id) {
          position = 'right'
        }

        if (channelData[selectedChannel].parent != null) {
          if (channelData[selectedChannel].parent === obj.message.publisher) {
            position = 'right'
          }
          if (channelData[selectedChannel].parent === obj.message.parentPublisher) {
            position = 'right'
          }
        }
        const finalJSON = {
          position,
          type: 'text',
          category: obj.message.type,
          text: actualText,
          date: d,
        }
        messages.push(finalJSON)
        lastTime = Math.round(obj.timetoken / 10000000)
      })
      if (selectedChannel !== '') {
        store.dispatch({
          type: actions.SET_HISTORY_MESSAGE,
          payload: {
            chatWith: selectedChannel,
            lastTime,
            toAdd: messages,
          },
        })
      }
      return ''
    })
  } catch (err) {
    console.log(err)
    console.log(err.status)
  }
}

// export function* FETCH_REQUESTS(data) {
//   try {
//     const fetchResult = yield call(pubnubFetchMessages, data.payload)
//     const temporaryFetchRequest = {}
//     Object.entries(fetchResult.channels).map(key => {
//       key[1].forEach(obj => {
//         if (obj.message.type === 'subscribe' && obj.message.ChannelType === 'business') {
//           if (Object.prototype.hasOwnProperty.call(obj.message, 'publisher')) {
//             temporaryFetchRequest[obj.message.publisher] = {
//               channelID: obj.message.subscribeTo,
//               channelType: obj.message.ChannelType,
//               chatWith: obj.message.publisher,
//               requestFor: obj.message.requestFor,
//               userMeta: obj.message.userMeta,
//             }

//             // reduxStore.dispatch({
//             //   type: actions.ADD_REQUESTS,
//             //   payload: {
//             //     actualload: {
//             //       channelID: obj.message.subscribeTo,
//             //       channelType: obj.message.ChannelType,
//             //       chatWith: obj.message.publisher,
//             //       requestFor: obj.message.requestFor,
//             //       userMeta: obj.message.userMeta,
//             //     },
//             //   },
//             // })
//           }
//         }
//         if (obj.message.type === 'deleteRequest') {
//           if (Object.prototype.hasOwnProperty.call(temporaryFetchRequest, obj.message.deleteFor)) {
//             delete temporaryFetchRequest[obj.message.deleteFor]
//           }
//         }
//       })
//       return ''
//     })

//     reduxStore.dispatch({
//       type: actions.SET_STATE,
//       payload: {
//         requestData: temporaryFetchRequest,
//       },
//     })
//   } catch (err) {
//     console.log(err)
//     console.log(err.status)
//   }
// }

// export function* ASSIGN_EXHIBITOR(data) {
//   try {
//     const state = yield select()
//     const {
//       Chat: { requestData, pubnub, channelData },
//       virtualUser: { id, name, accessToken },
//     } = state

//     const selectedRequest = requestData[data.payload.chatWith]
//     yield put({
//       type: actions.ADD_CHANNEL,
//       payload: {
//         actualload: {
//           channelID: selectedRequest.channelID,
//           channelType: selectedRequest.channelType,
//           chatWith: selectedRequest.chatWith,
//           parent: selectedRequest.requestFor,
//           messages: [],
//           lastTime: 631132200,
//           userMeta: selectedRequest.userMeta,
//         },
//       },
//     })

//     yield call(pubnubSubscribe, { pubnub, channels: [selectedRequest.channelID] })
//     const fetchResult = yield call(pubnubFetchMessages, {
//       pubnub,
//       channels: [selectedRequest.channelID],
//     })
//     Object.entries(fetchResult.channels).map(key => {
//       let selectedChannel = ''

//       Object.entries(channelData).map(item => {
//         if (item[1].channelID === key[0]) {
//           selectedChannel = item[1].chatWith
//           return item[1].chatWith
//         }
//         return ''
//       })
//       const messages = []
//       let lastTime = 631132200
//       key[1].forEach(obj => {
//         const utcSeconds = Math.round(obj.timetoken / 10000000)
//         const d = new Date(0) // The 0 there is the key, which sets the date to the epoch
//         d.setUTCSeconds(utcSeconds)
//         let actualText = obj.message.content
//         if (obj.message.type === 'videomeet') {
//           actualText = <MeetingInvite id={obj.message.data} />
//         }
//         const finalJSON = {
//           position: obj.message.publisher === data.payload.chatWith ? 'left' : 'right',
//           type: 'text',
//           category: obj.message.type,
//           text: actualText,
//           date: d,
//         }
//         messages.push(finalJSON)
//         lastTime = Math.round(obj.timetoken / 10000000)
//       })
//       if (selectedChannel !== '') {
//         reduxStore.dispatch({
//           type: actions.SET_HISTORY_MESSAGE,
//           payload: {
//             chatWith: selectedChannel,
//             lastTime,
//             toAdd: messages,
//           },
//         })
//       }
//       return ''
//     })

//     const tempRequestData = requestData
//     delete tempRequestData[data.payload.chatWith]
//     yield put({
//       type: actions.SET_STATE,
//       payload: {
//         requestData: tempRequestData,
//         currentScreen: 'chat',
//         currentTab: 'messages',
//         currentChat: data.payload.chatWith,
//       },
//     })

//     yield call(pubnubPublish, {
//       pubnub,
//       channelID: selectedRequest.channelID,
//       message: {
//         type: 'assignExhibitor',
//         parentPublisher: selectedRequest.requestFor,
//         assignedTo: {
//           id,
//           name,
//         },
//       },
//     })

//     yield call(saveChat, {
//       ChannelName: selectedRequest.channelID,
//       ChannelType: 'business',
//       token: accessToken,
//       ChatWith: selectedRequest.chatWith,
//       assignedTo: {
//         id,
//         name,
//       },
//     })
//   } catch (err) {
//     console.log(err)
//     console.log(err.status)
//   }
// }

// export function* UNASSIGN_EXHIBITOR(data) {
//   try {
//     const state = yield select()
//     const {
//       Chat: { pubnub, channelData },
//       virtualUser: { accessToken },
//     } = state

//     const selectedChannel = channelData[data.payload.currentChat]
//     yield call(pubnubPublish, {
//       pubnub,
//       channelID: selectedChannel.channelID,
//       message: {
//         type: 'unassignExhibitor',
//         parentPublisher: selectedChannel.requestFor,
//         assignedTo: {},
//       },
//     })

//     yield call(saveChat, {
//       ChannelName: selectedChannel.channelID,
//       ChannelType: 'business',
//       token: accessToken,
//       ChatWith: selectedChannel.chatWith,
//       assignedTo: {},
//     })

//     const tempchannelData = channelData
//     delete tempchannelData[data.payload.currentChat]
//     yield put({
//       type: actions.SET_STATE,
//       payload: {
//         channelData: tempchannelData,
//         currentScreen: 'homescreen',
//         currentTab: 'messages',
//         currentChat: '',
//       },
//     })
//   } catch (err) {
//     console.log(err)
//     console.log(err.status)
//   }
// }

// export function* DELETE_REQUEST(data) {
//   try {
//     const state = yield select()
//     const {
//       Chat: { pubnub, requestData },
//     } = state

//     const selectedRequest = requestData[data.payload.chatWith]
//     yield call(pubnubPublish, {
//       pubnub,
//       channelID: selectedRequest.requestFor,
//       message: {
//         type: 'deleteRequest',
//         deleteFor: data.payload.chatWith,
//         ChannelType: 'business',
//       },
//     })
//   } catch (err) {
//     console.log(err)
//     console.log(err.status)
//   }
// }

// export function* FETCH_LEADS(data) {
//   try {
//     const result = yield call(getLeads, data.payload.theScene)
//     if (result.status === 200) {
//       yield put({
//         type: eventActions.SET_STATE,
//         payload: {
//           leads: result.data,
//         },
//       })
//     }
//   } catch (err) {
//     console.log(err)
//     console.log(err.status)
//   }
// }

export function* UNSUBSCRIBE_CHANNELS(data) {
  console.log('data',data)
  try {
    const subsResult = yield call(pubnubUnSubscribe, data.payload)
    console.log('response of unsubscription')
    console.log(subsResult)
  } catch (err) {
    console.log(err.status)
  }
}

export function* ADD_MESSAGE(data) {
  const { payload: { messageEvent } } = data
      const state = yield select()
      const {
        Chat: { pubnub, channelData,currentChat },
        virtualUser: { id },
      } = state
  if (messageEvent.message.publisher !== id) {
    const utcSeconds = Math.round(messageEvent.timetoken / 10000000)
    const d = new Date(0) // The 0 there is the key, which sets the date to the epoch
    d.setUTCSeconds(utcSeconds)
    let readStatus = 'read'
    // console.log('test', channelData, messageEvent.message.publisher)
    if (Object.prototype.hasOwnProperty.call(channelData, messageEvent.message.publisher)) {
      console.log('messageEvent', messageEvent)

      let unreadCount = channelData[messageEvent.message.publisher].unreadCount
      if (currentChat !== messageEvent.message.publisher) {
        readStatus = 'unread'
        unreadCount += 1
      }
      let actualText = messageEvent.message.content
      // if (messageEvent.message.type === 'videomeet') {
      //   actualText = <MeetingInvite id={messageEvent.message.content.props.id} />
      // }
      // if (messageEvent.message.type === 'visitingcard') {
      //   actualText = <VisitingCard userData={messageEvent.message.content.props.userData} />
      // }
      yield put({
        type: actions.SET_MESSAGE,
        payload: {
          chatWith: messageEvent.message.publisher,
          lastTime: Math.round(messageEvent.timetoken / 10000000),
          readStatus,
          unreadCount,
          toAdd: {
            position: 'left',
            type: 'text',
            category: messageEvent.message.type,
            text: actualText,
            date: d,
          },
        },
      })
      if (currentChat !== messageEvent.message.publisher || !isSidebarOpen) {
        if (messageEvent.message.type === 'videomeet') {
          notification.open({
            message: 'New Video Meet Invite',
            description: "Kindly tap to read",
            placement: "topLeft",
            // onClick: this.openChatBox
          });
        }
        else {
          notification.open({
            message: 'New Message',
            description: actualText.length > 40 ? `${actualText.substring(0, 40)}...` : actualText,
            placement: "topLeft",
            // onClick: this.openChatBox
          });
        }

      }
    }
  }
}



// export function* FOLLOW_ME_TO_LIVE_SESSION() {
//   try {
//     const allAgendas = yield call(getAgenda)
//     if (allAgendas.status === 200) {

//       const theCompleteAgenda = allAgendas.data
//       if(Object.keys(theCompleteAgenda).length !== 0)
//       {
//         yield put({
//           type: 'agenda/SET_STATE',
//           payload: {
//             agendas: theCompleteAgenda
//           },
//         })
//         const theBaseAgenda = theCompleteAgenda[Object.keys(theCompleteAgenda)[0]]
//         const theBaseSession = theBaseAgenda.agendaItems[Object.keys(theBaseAgenda.agendaItems)[0]]
//         const id = Object.keys(theBaseAgenda.agendaItems)[0]
//         const scene = theBaseSession.assignedRoom
//         const { url } = theBaseSession

//         yield put({
//           type: eventActions.FILTER_SCENE,
//           payload: {
//             id: scene,
//             navigated: true,
//             componentRendered: false,
//             sessionID: id,
//             currentSessionURL: url,
//             callScene: true
//           },
//         })
//       }
//     }
//   } catch (err) {
//     console.log(err.status)
//   }
// }

export default function* rootSaga() {
  yield all([
    // takeEvery(actions.GET_ALL_USERS, GET_ALL_USERS),
    takeEvery(actions.INIT_CHAT, INIT_CHAT),
    takeEvery(actions.CREATE_CHANNEL, CREATE_CHANNEL),
    takeEvery(actions.SEND_MESSAGE, SEND_MESSAGE),
    takeEvery(actions.SUBSCRIBE_CHANNELS, SUBSCRIBE_CHANNELS),
    takeEvery(actions.UNSUBSCRIBE_CHANNELS, UNSUBSCRIBE_CHANNELS),
    takeEvery(actions.GET_CHANNELS, GET_CHANNELS),
    takeEvery(actions.FETCH_MESSAGES, FETCH_MESSAGES),
    // takeEvery(actions.FETCH_REQUESTS, FETCH_REQUESTS),
    // takeEvery(actions.ASSIGN_EXHIBITOR, ASSIGN_EXHIBITOR),
    // takeEvery(actions.UNASSIGN_EXHIBITOR, UNASSIGN_EXHIBITOR),
    // takeEvery(actions.DELETE_REQUEST, DELETE_REQUEST),
    // takeEvery(actions.FETCH_LEADS, FETCH_LEADS),
    // takeEvery(actions.LOAD_MORE_USERS, LOAD_MORE_USERS),
    // takeEvery(actions.SEARCH_USER,SEARCH_USER),
    // takeEvery(actions.FOLLOW_ME_TO_LIVE_SESSION,FOLLOW_ME_TO_LIVE_SESSION),
    takeEvery(actions.ADD_MESSAGE, ADD_MESSAGE)
  ])
}
