import actions from "./actions";

import { Image } from 'antd';

const initialState = {
  stages: {},
  currentStage: "",
  isStageSetupModalOpen: false,
  stageaddLoading: false,
  saveStageLoader: false,
  isStreamingModalOpen: false,
  selectedStage: "",
  theStreamingURL: "",
  roles: [],
  backstageOptions: {
    restream: "Restream",
    vmix: "V-Mix Call",
    streamyard: "Streamyard",
    streamclub: "Stream.club (Advanced)",
    zoom: "Zoom (Redirection)",
    teams: "Microsoft Teams (Redirection)",
    webex: "WebEx (Redirection)",
    bluejeans: "Blue Jeans (Redirection)",
  },
  
};

export default function stageSetupReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    case actions.SET_STAGE:
      return {
        ...state,
        stages: {
          ...state.stages,
          [action.payload.currentStage]: {
            ...state.stages[action.payload.currentStage],
            [action.payload.key]: action.payload.actualload,
          },
        },
      };
    default:
      return state;
  }
}
