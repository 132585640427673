import React from 'react'
import { Modal, Row, Col } from 'antd'
import { useDispatch, useSelector } from "react-redux";
import { CloseCircleTwoTone } from '@ant-design/icons'
import { Scrollbars } from 'react-custom-scrollbars'
import actions from 'redux/virtual/altRealm/actions'
import GamingScreen from './GamingScreen'

import './gaming.less'

export default function GamingModal() {
  const requiredState = useSelector(({altRealm})=>({altRealm}))
  const {altRealm: {isGamingModalOpen}} = requiredState
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch({
      type: actions.SET_STATE,
      payload: {
        isGamingModalOpen: false,
        isGameStart: false,
      },
    })
  }


    return (
      <div>
        <Modal
          visible={isGamingModalOpen}
          style={{
            top: 0,
            display: 'flex',
          }}
          wrapClassName="socialwallModal"
          maskClosable={false}
          // maskStyle={{ marginTop: '48px' }}
          mask
          width="72vw"
          onOk={handleClose}
          confirmLoading
          onCancel={handleClose}
          footer={null}
          closeIcon={<CloseCircleTwoTone twoToneColor="#eb2f96" style={{ fontSize: '25px' }} />}
          destroyOnClose
        >
          <Row id="modalContent" className="modalContent">
            <Scrollbars autoHeight autoHeightMin="100px" autoHeightMax="calc(100vh - 50px)">
              <Col span={24}>
                <GamingScreen />
              </Col>
            </Scrollbars>
          </Row>
        </Modal>
      </div>
    )
}

