import React, { useState } from "react";

import { Upload, Tooltip, Button, Space,message,Progress } from "antd";
import { RiEdit2Fill,RiUploadCloud2Line,RiExchangeBoxFill } from "react-icons/ri";
import actions from 'redux/organizer/eventSetup/actions';
import { useDispatch, useSelector } from "react-redux";
import ImageEditor from "../ImageEditor";
import { uploadFile } from 'services/organizer/eventSetup';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}


export default function FileUpload(props) {
    const requiredState = useSelector(({eventSetup})=>({eventSetup}))
    const dispatch = useDispatch();
    const {theFile, fileSave,fileType} = props
    const {eventSetup:{currentScene,}} = requiredState
    const [progress, setProgress] = useState(0);


    const  beforeUpload = (file) => {
        dispatch({
            type: actions.SET_STATE,
            payload: {
              imageEditorOpen: true,
              imageEditorFile: URL.createObjectURL(file)
            },
          })
    }

    const editImage = (fileURL) => {
      dispatch({
        type: actions.SET_STATE,
        payload: {
          imageEditorOpen: true,
          imageEditorFile: fileURL
        },
      })
    }

    const openImage = () => {
      dispatch({
        type: 'altRealm/SET_STATE',
        payload: {
          imagePopOpen: true,
          imagePopURL: theFile,
        },
      })
    }


    const fileUploadNew = async options => {
      const { file, onSuccess, onProgress, onError, data } = options
      try{
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: event => {
            const percent = Math.floor((event.loaded / event.total) * 100);
            setProgress(percent);
            if (percent === 100) {
              setTimeout(() => setProgress(0), 1000);
            }
            console.log("uploaded: "+percent)
            onProgress({ percent: (event.loaded / event.total) * 100 });
          }
        }
        const result = await uploadFile({ file: file, config })
        props.fileSave(result.data.fileUrl)
        onSuccess('ok')
        message.success('File Uploaded Successfully')
      }
      catch(e){
        console.log(e)
        onError({ event: e })
        message.error('Error occured in uploading the File.')
      }

    }

    return (
    <>
        {
            theFile === "" || theFile === undefined
            ?
                fileType === "image"
                ?
                <Upload beforeUpload={beforeUpload}>
                    <Button icon={<RiUploadCloud2Line />}>Upload Image</Button>
                </Upload>
                :
                <>
                  <Upload customRequest={fileUploadNew}>
                      <Button icon={<RiUploadCloud2Line />}>Upload File</Button>
                  </Upload>
                  {progress > 0 ? <Progress percent={progress} /> : null}
                </>
            :
                <>
                    {
                    fileType === "image"
                    ?
                    <>
                    <img alt="theFile" style={{ width: "50%", cursor:"pointer" }} src={theFile} onClick={openImage} />
                    <br />
                    <br />
                    <Tooltip title="Replace Image">
                      <Button
                        type="primary"
                        shape="circle"
                        size="small"
                        onClick={() => editImage(theFile)}
                        icon={<RiEdit2Fill className="remix-icon" />}
                      />
                    </Tooltip>
                    &nbsp;&nbsp;&nbsp;
                    <Tooltip title="Change Image">
                      <Upload beforeUpload={beforeUpload}>
                        <Button
                          type="primary"
                          shape="circle"
                          size="small"
                          icon={<RiExchangeBoxFill className="remix-icon" />}
                        />
                      </Upload>
                    </Tooltip>
                    </>
                    :
                    <>
                    <p className="hp-text-color-primary-1">{theFile}</p>
                    <Tooltip title="Change File">
                      <Upload customRequest={fileUploadNew}>
                        <Button
                          type="primary"
                          shape="circle"
                          size="small"
                          icon={<RiExchangeBoxFill className="remix-icon" />}
                        />
                      </Upload>
                    </Tooltip>
                    {progress > 0 ? <Progress percent={progress} /> : null}
                    </>
                  }
                </>

        }
        <ImageEditor approvedAspectRatios={["1:1","16:9"]} onImageSave={(data) => fileSave(data)}/>
    </>       
    )
}