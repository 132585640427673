import { all, takeEvery, call, put, select } from 'redux-saga/effects'
import { message } from 'antd'
import actions from './actions'

export function* GET_INIT() {
  const state = yield select()
  const {
    virtualUser: { id },
  } = state
  try {
    const setupData = yield call(getSavedStageData)
    if (Object.keys(setupData.data.stages).length > 0) {
      const final = setupData.data.stages
      const theOrder = setupData.data.order
      yield put({
        type: actions.SET_STATE,
        payload: {
          stages: final
        },
      })
    }

    const roles = yield call(getRoles)
    console.log(roles,"getRoles")

    if (roles.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          roles: roles.data,
        },
      })
    }

  } catch (err) {
    console.log(err)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* SAVE_DATA() {
  try {
    yield put({
      type: actions.SET_STATE,
      payload: {
        saveStageLoader: true
      },
    })
    const state = yield select()
    const { stageSetup: {stages }} = state
    const result = yield call(writeData, { load: {stages } })
    if (result.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          saveStageLoader: false
        },
      })
      message.success('Stages are updated successfully.')
    } else {
      message.error('Some Error Updating the DB')
    }
  } catch (err) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        saveStageLoader: false
      },
    })
    message.error('Some Error Updating the Stage. Try again by changing the values')
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}



export function* DELETE_STAGE(data) {
  try {
    const result = yield call(deleteStage, data.payload.theStage)
    console.log(result)
    if (result.status === 200) {
      const state = yield select()
      const {
        stageSetup: { stages },
      } = state
      const tempStages = stages
      delete tempStages[data.payload.theStage];
      yield put({
        type: actions.SET_STATE,
        payload: {
          stages: tempStages
        },
      })
      message.success('Stage Deleted Successfully')
    }
  } catch (err) {
    console.log(err)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}


export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_INIT, GET_INIT),
    takeEvery(actions.SAVE_DATA, SAVE_DATA),
    takeEvery(actions.DELETE_STAGE, DELETE_STAGE)
  ])
}
