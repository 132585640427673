import React from "react";
import { Row, Col, Card } from 'antd'
import abgdata from './ABGData.json'
import { useDispatch, useSelector } from "react-redux";
// import actions from 'redux/virtual/altRealm/actions';

export default function ImageView() {
  const requiredState = useSelector(({ altRealm }) => ({ altRealm }))
  const { altRealm: { realms, currentRealm, interactionIndex } } = requiredState
  const theChosenRealm = realms[currentRealm]
  let theChosenProduct = ""

  //Uncomment this code when actual image interaction is required
  // if(interactionIndex !== "")
  // {
  //   if(typeof theChosenRealm.images.data[interactionIndex] !== 'undefined') {
  //     theChosenProduct = theChosenRealm.images.data[interactionIndex]
  //   }
  // }

  const theProduct = abgdata[interactionIndex - 1]
  theChosenProduct = {
    url: interactionIndex !== "" ? `https://altyug.s3.ap-south-1.amazonaws.com/abgmetaverse/${interactionIndex}.jpg`: "",
    name: theProduct?.ProjectName,
  }
  return (
    <>
      <Row>
        <Col span={15}>
          <img src={theChosenProduct.url} style={{ width: "100%", borderRadius: "20px" }} alt={theChosenProduct.name} />
        </Col>
        <Col span={1} />
        <Col span={7}>
          <br />
          <br />
          <Row>
            <Col span={24}>
              <h3 style={{ fontWeight: "800" }}>{theChosenProduct.name}</h3>
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{color: "white"}}>
              {/* <div style={{color: "white"}} dangerouslySetInnerHTML={{__html: theChosenProduct.description}} /> */}
              {/* <p style={{color: "white"}}>{theChosenProduct.description}</p> */}
              <p>
                <b>Category:</b>&nbsp;{theProduct?.Category}
              </p>
              <br />
              <b>The Team:</b>
              <br />
              <ol>
                <li>
                  <b>{theProduct?.Manager}</b>
                </li>
                <li>{theProduct?.Team1}</li>
                {theProduct?.Team2 !== "" ? <li>{theProduct?.Team2}</li> : ""}
                {theProduct?.Team3 !== "" ? <li>{theProduct?.Team3}</li> : ""}
                {theProduct?.Team4 !== "" ? <li>{theProduct?.Team4}</li> : ""}
              </ol>
            </Col>
          </Row>
        </Col>
        <Col span={1} />
      </Row>
    </>
  );
}
