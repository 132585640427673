import React,{useEffect} from 'react'
import { useSelector,useDispatch } from 'react-redux';

import { ConfigProvider } from 'antd';

import Router from "./router/Router";
import ImagePop from './view/common/components/ImagePop';
import AvatarCustomizer from 'view/common/components/AvatarCustomizer';
import RealmEditor from 'view/common/components/RealmEditor';
import InteractionDrawer from 'view/common/components/InteractionDrawer';
import InteractionModal from 'view/common/components/InteractionModal';
import actions from 'redux/virtual/user/actions'
import StreamArena from 'view/virtual/pages/StreamActions';
import DeviceOrientation, { Orientation } from 'react-screen-orientation'
export default function App() {
  const requiredState = useSelector(({ virtualUser }) => ({ virtualUser }));
  const dispatch = useDispatch();
  const customise = useSelector(state => state.customise)

  

  return (
    
    <ConfigProvider direction={customise.direction}>
      <Router />
      <DeviceOrientation lockOrientation={'landscape'}>
        <Orientation orientation='landscape' alwaysRender={false}>


          
      <ImagePop />
      {/* <AvatarCustomizer /> */}
      {/* <StreamArena/> */}
      <RealmEditor />
      <InteractionDrawer />
      <InteractionModal />
        </Orientation>
    </DeviceOrientation>

    </ConfigProvider>
  );
}