const actions = {
    SET_STATE: 'virtaluser/SET_STATE',
    LOGIN: 'virtaluser/LOGIN',
    SEND_OTP: 'virtaluser/SEND_OTP',
    LOAD_CURRENT_ACCOUNT: 'virtaluser/LOAD_CURRENT_ACCOUNT',
    LOGOUT: 'virtaluser/LOGOUT',
    FAKE_LOGOUT: 'virtaluser/FAKE_LOGOUT',
    LOGIN_HELPER: 'virtualuser/LOGIN_HELPER',
    FORGOT_PASSWORD: 'virtualUser/FORGOT_PASSWORD',
    RESET_PASSWORD: 'virtualUser/RESET_PASSWORD',
    EDIT_AVATAR: 'virtualUser/EDIT_AVATAR',
    COMPLETE_EDIT_PROFILE: 'virtualUser/COMPLETE_EDIT_PROFILE',
    GIVE_RATING: 'virtualUser/GIVE_RATING',
    GET_ALL_RATINGS: 'virtualUser/GET_ALL_RATINGS',
    UPDATE_ELEMENT_SCORE: 'virtualUser/UPDATE_ELEMENT_SCORE',
    VALIDATE_SESSION: 'virtualUser/VALIDATE_SESSION',
  GET_LEADERBOARD: 'virtualUser/GET_LEADERBOARD',
  GET_ALL_NOTIFICATIONS:'virtualUser/GET_ALL_NOTIFICATIONS',


  }
  
  export default actions  