import React, { useState } from "react";
import { Row, Col, Button, Modal,Popover } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Popup } from "react-chat-elements";
import { BarChartOutlined } from "@ant-design/icons";
import PollingForm from "./PollingForm";
import "./polling.less";
import "../../streamFeatures.less"
// import styles from '../../style.module.scss'

function ChatPollingFooter() {
  const [showPollForm, setshowPollForm] = useState(false);
  const requiredState = useSelector(({virtualUser})=>({virtualUser}))
  const {virtualUser: {roles}} = requiredState
  const closePollForm = () => {
    console.log("checking if came in closepoll")
    setshowPollForm(false);
  };

  const popmeup = () => {
    setshowPollForm(true);
  };

  const handleOpenChange = (newOpen) => {
    setshowPollForm(newOpen);
  };

  // const {
  //   streamFeature: { currentReplyTo, currentScreen },
  // } = this.props
  console.log("checking poll  "+ showPollForm)
  return (
    <>
    {(roles.includes('ROLE_MODERATOR')) || true ?
      <Row justify="space-around" align="middle" className="footer">
        <Col span={24} className="text-center">
        {/* <Modal title="Basic Modal" open={showPollForm} footer={null} style={{zIndex: 999999999}}>
          <PollingForm closePoll={closePollForm} />
        </Modal> */}
        <Popover
          content={showPollForm ? <PollingForm closePoll={closePollForm} /> : ""}
          title="Create Poll"
          trigger="click"
          visible={showPollForm}
          onOpenChange={handleOpenChange}
        >
          <Button type="primary" icon={<BarChartOutlined />} size="medium" onClick={popmeup}>
            Add new Poll
          </Button>
        </Popover>
        </Col>
      </Row>
      :
      ""
    }
  </>
    // <Drawer
    //   style={
    //     showPollForm
    //       ? {
    //           zIndex: 100,
    //           width: "18vw",
    //           bottom: "12%",
    //           right: 0,
    //           left: "auto",
    //           height: "50vh",
    //         }
    //       : { display: "none" }
    //   }
    //   title="Basic Drawer"
    //   placement={"bottom"}
    //   closable={false}
    //   onClose={closePollForm}
    //   visible={showPollForm}
    //   // key={placement}
    //   getContainer={false}
    // >
    //   <PollingForm closePoll={closePollForm} />
    // </Drawer>
  );
}

export default ChatPollingFooter;
