import React from 'react'
import { Collapse, Table } from 'antd'
import 'antd/dist/antd.css'

/* eslint-disable react/jsx-indent, camelcase,no-nested-ternary */

const { Panel } = Collapse

function ScoreBoard (props) {
    const { scoreboard } = props
    console.log('checking scoreboard')
    console.log(scoreboard)
    const columns = [
      {
        title: 'Game Name',
        dataIndex: 'name',
      },
      {
        title: 'High Score',
        dataIndex: 'score',
        sorter: {
          compare: (a, b) => a.score - b.score,
          multiple: 3,
        },
      },
      {
        title: 'Coins Received',
        dataIndex: 'points',
        sorter: {
          compare: (a, b) => a.points - b.points,
          multiple: 3,
        },
      },
      {
        title: 'Conversion',
        dataIndex: 'pointing_system',
      },
    ]
    return (
      <Collapse accordion>
        <Panel header="See Your Score Board" key="1">
          <Table columns={columns} dataSource={scoreboard} pagination={false} scroll={{ y: 100 }} />
        </Panel>
      </Collapse>
    )
  }

export default ScoreBoard
