import React from 'react';
import {Drawer} from 'antd'
import { useDispatch, useSelector } from "react-redux";
import actions from 'redux/virtual/altRealm/actions';
import ArcadeGames from './components/ArcadeGames';
import ProductView from './components/ProductView';
import {
  CloseCircleTwoTone
} from '@ant-design/icons';
import "./interactionDrawer.less"

export default function InteractionDrawer() {
  const requiredState = useSelector(({altRealm})=>({altRealm}))
  const dispatch = useDispatch();
  const {altRealm:{isInteractionDrawerVisible,interactionDrawerModule}} = requiredState


  const onClose  = () => {
    dispatch({
      type: actions.SET_STATE,
      payload: {
        isInteractionDrawerVisible: false,
        interactionDrawerModule: "",
        interactionIndex: "",
        arcadeGameStep: 0,
        arcadeGameURL: ""
      },
    })
  }

  const availableModule = {
    productView: <ProductView />,
    staticGames: <ArcadeGames />
  }

  return (
    <>
    <div className='closeIcon' style={{ display: isInteractionDrawerVisible ? "block" : "none" }}>
      <CloseCircleTwoTone twoToneColor="#eb2f96" onClick={onClose} />
    </div>
    <Drawer placement="right" className="interactionDrawer" onClose={onClose} visible={isInteractionDrawerVisible}>
      {availableModule[interactionDrawerModule]}
      {/* <h3>ArcadeGames</h3> */}
    </Drawer>
    </>
  );
}
          