import { all, put, call, takeEvery,select } from 'redux-saga/effects'
import actions from './actions'

export function* GET_DATA() {
  console.log("now ok?")
}

export default function* rootSaga() {
  yield all([
      takeEvery(actions.GET_DATA, GET_DATA)
    ])
}
