import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Row, Col,List } from 'antd'
import { MessageList } from 'react-chat-elements'
import { isMobile } from "react-device-detect";
import { Scrollbars } from 'react-custom-scrollbars'
import actions from 'redux/virtual/streamSession/actions'
import { DownCircleOutlined } from '@ant-design/icons'
import './react-chat-elements.modified.css'
import ChatComponent from "./ChatComponent"
import "../../streamFeatures.less"

let isInitialLoad = false
export default function ChatContent(props) {
  const dispatch = useDispatch();
  const requiredState = useSelector(({streamSession,altRealm})=>({streamSession,altRealm}))
  const {streamSession: {chat,currentChatScenario},altRealm: {currentRealm, roomName}} = requiredState
  let chatscrollbars = useRef();
  const [mounted, setMounted] = useState(false);
  useEffect(() => {setMounted(true)},[]);

  let theChat = []
  if(currentChatScenario === "inroom"){
    if(chat.hasOwnProperty(roomName))
    {
      theChat = chat[roomName]
    }
  }
  else{
    if(chat.hasOwnProperty(currentRealm))
    {
      theChat = chat[currentRealm]
    }
  }
  

  useEffect(() => {
    if(!isInitialLoad){
      if(theChat.length > 0)
      {
        mounted && chatscrollbars.current.scrollToBottom()
        isInitialLoad = true
      }
    }

    if(chatscrollbars.current !== null){
      const chatValues = chatscrollbars.current.getValues()
    chatscrollbars.current.scrollToBottom()

      if(chatValues.top > 0.80 || !isInitialLoad || (chatValues.clientHeight / chatValues.scrollHeight) > 0.7 )
      {
        mounted && chatscrollbars.current.scrollToBottom() 
      }
    }
  },[theChat])


  const takemetochatbottom = () => {
    chatscrollbars.current.scrollToBottom()
  }

  const replyClick = event => {
    const finalJSON = {
      text: typeof event.text === "string" ? event.text : "Replying to Unapproved Message",
      title: event.title,
      actualtext: typeof event.text === "string" ? event.text : "Replying to Unapproved Message",
      avatar: event.avatar,
    }
    finalJSON.text =
      finalJSON.text.length > 35 ? `${finalJSON.text.substring(0, 35)}...` : finalJSON.text
    dispatch({
      type: actions.SET_STATE,
      payload: {
        currentReplyTo: finalJSON,
      },
    })
  }
    let chatActivateBottom = false
    let chatAllValues = {top: 1}
    if(chatscrollbars.current !== null && chatscrollbars.current !== undefined){
      chatActivateBottom = true
      chatAllValues = chatscrollbars.current.getValues()
    }

    let width = "100%"
    let height = "100%"
  
    if(isMobile)
    {
      width = window.innerWidth
      height = 0.5625*width
    }
    const theContainer = height+178 //combining top bar + tabs + footer
    return (
      <>
        <Row className="chatContent">
          <Col span={24}>
            <Scrollbars  ref={chatscrollbars} style={isMobile? {height:"calc(100vh - 455px)", position:"none"}:{height:"calc(85vh - 100px)"}} >
              {/* <MessageList
                className="message-list"
                lockable
                style={{ width: '50%', fontSize: '30px' }}
                toBottomHeight="100%"
                dataSource={chat}
                onReplyClick={replyClick}
                onReplyMessageClick={() => {
                  console.log('onReplyMessageClick')
                }}
              /> */}
              <List
                dataSource={theChat}
                renderItem={item => (
                  <List.Item>
                    <ChatComponent data={item} />
                  </List.Item>
                )}
                className="chatList"
              />
            </Scrollbars>
          </Col>
        </Row>
        {chatActivateBottom ? 
          <div className="backtobottom" style={{display: chatAllValues.top < 0.84 && chatAllValues.clientHeight !== chatAllValues.scrollHeight ? "block" : "none"}}>
            <DownCircleOutlined onClick={takemetochatbottom} />
          </div>
        :
          ''
        }
      </>
    )
  }
