import React from 'react';
import {
  Row,
  Col,
} from 'antd'
import MoveControl from "assets/images/icons/move.png"
import KeysControl from "assets/images/icons/keys.png"
import ShiftControl from "assets/images/icons/shift.png"
import SpacebarControl from "assets/images/icons/spacebar.png"
import one from "assets/images/icons/1.png"
import two from "assets/images/icons/2.png"
import three from "assets/images/icons/3.png"
import four from "assets/images/icons/4.png"
import five from "assets/images/icons/5.png"
import clap from "assets/images/icons/b.png"
import capture from "assets/images/icons/c.png"
import "./instructions.less"

export default function Controls() {



  return (
    <>
      <Row>
        <Col span={1} />
        <Col span={5} className="hp-text-center">
          <img src={MoveControl} style={{ width: "100%" }} />
          <br />
          Grab and drag the mouse to look around
        </Col>
        <Col span={1} />
        <Col span={5} className="hp-text-center">
          <img src={KeysControl} style={{ width: "100%" }} />
          <br />
          Hold Shift with WASD keys to run
        </Col>
        <Col span={1} />
        <Col span={5} className="hp-text-center">
          <img src={ShiftControl} style={{ width: "100%" }} />
          <br />
          Hold Shift with WASD keys to run
        </Col>
        <Col span={1} />
        <Col span={5} className="hp-text-center">
          <img src={SpacebarControl} style={{ width: "100%" }} />
          <br />
          Use Spacebar to Jump
        </Col>
      </Row>
      <br />
      <Row>
        <Col span={24} className="hp-text-center">
          <h4 style={{ color: "black" }}>Quick Navigation inside Altyug</h4>
        </Col>
      </Row>
      <Row>
        <Col span={2} />
        <Col span={3} className="hp-text-center">
          <img src={one} style={{ width: "70%" }} />
          <br />
          Jump to Auditorium
        </Col>
        <Col span={3} className="hp-text-center">
          <img src={two} style={{ width: "70%" }} />
          <br />
          Jump to Nomination Wall
        </Col>
        <Col span={3} className="hp-text-center">
          <img src={three} style={{ width: "70%" }} />
          <br />
          Jump to Arcade Games
        </Col>
        <Col span={3} className="hp-text-center">
          <img src={four} style={{ width: "70%" }} />
          <br />
          Jump to Spawning Area
        </Col>
        <Col span={3} className="hp-text-center">
          <img src={five} style={{ width: "70%" }} />
          <br />
          Jump to Photobooth
        </Col>
        <Col span={3} className="hp-text-center">
          <img src={clap} style={{ width: "70%" }} />
          <br />
          Press to Clap
        </Col>
        <Col span={3} className="hp-text-center">
          <img src={capture} style={{ width: "70%" }} />
          <br />
          Press to take Photo
        </Col>
        <Col span={1} />
      </Row>

    </>
  );
}
