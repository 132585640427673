import React from 'react'
import ChatFooter from '../../LayoutComponent/ChatFooter'
import ChatContent from '../../LayoutComponent/ChatContent'

export default function LiveChat() {


  
  return (
    <>
      <ChatContent />
      <ChatFooter />
    </>
  )
}
