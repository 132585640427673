import React from 'react';
import {
  Row,
  Col,
  Button,
} from 'antd'
import { useDispatch } from "react-redux";
import actions from 'redux/virtual/realmEditor/actions';
import realmActions from 'redux/virtual/altRealm/actions'
import "../../editor.less"
import uuid from 'react-uuid';

export default function Header(props) {
  const dispatch = useDispatch();
  const {theChosenCategory,currentEditorScreen} = props

  const addItem  = () => {
    const clonedChosenCategory = {...theChosenCategory}
    const temptheChosenCategory = clonedChosenCategory.data
    const sampleData = {...theChosenCategory.initialDataItem}
    sampleData.uuid = uuid()
    temptheChosenCategory.push(sampleData)
    dispatch({
      type: realmActions.SET_DATA,
      payload: {
        currentEditorScreen: currentEditorScreen,
        actualload: temptheChosenCategory
      },
    })
  }
  
  let theHeader = theChosenCategory.name
  let theSubtitle = theChosenCategory.subtitle
  return (
    <>
      <Col span={19}>
        <h2>{theHeader}</h2>
        <h5>{theSubtitle}</h5>
      </Col>
      <Col span={5} className="hp-text-center">
      {theChosenCategory !== "" && theChosenCategory !== null
      ?
        theChosenCategory.data.length !== 0 ?
          
            <Button type="primary" onClick={addItem}>Add {theChosenCategory.name}</Button>
          
        :
        ""
      :
      ""
      }
      </Col>
    </>
  );
}
          