import React from 'react';
import {
  Row,
  Col,
  Button,
  Empty
} from 'antd'
import emptyImage from "assets/images/illustrations/empty-groups.svg";
import { useDispatch, useSelector } from "react-redux";
import actions from 'redux/virtual/realmEditor/actions';
import realmActions from 'redux/virtual/altRealm/actions'
import ItemRow from "./ItemRow"
import "../../editor.less"
import uuid from 'react-uuid';

export default function MainContent(props) {
  const dispatch = useDispatch();
  const {theChosenCategory,currentEditorScreen,theCategoryInfo} = props
  const handleIconClick  = (theKey) => {
    dispatch({
      type: actions.SET_STATE,
      payload: {
        currentEditorScreen: theKey,
      },
    })
  }

  const addItem  = () => {
    const clonedChosenCategory = {...theChosenCategory}
    const temptheChosenCategory = clonedChosenCategory.data
    const sampleData = {...theCategoryInfo.initialDataItem}
    sampleData.uuid = uuid()
    temptheChosenCategory.push(sampleData)
    dispatch({
      type: realmActions.SET_DATA,
      payload: {
        currentEditorScreen: currentEditorScreen,
        actualload: temptheChosenCategory
      },
    })
  }


  let showcase = []
  console.log("we are checking thechosencategory")
  console.log(theChosenCategory)
  if(theChosenCategory !== "" && theChosenCategory !== null)
  {
    console.log("we are inside")
    console.log(theChosenCategory.data)
    theChosenCategory.data.forEach((key,index) => {
      console.log("are we looping")
      console.log(key)
      showcase.push(<><ItemRow theData={theChosenCategory} theCategoryInfo={theCategoryInfo} theIndex={index} currentEditorScreen={currentEditorScreen} /><br /><br /></>)
    })
  }


  return (
    <div className="theMain">
      {theChosenCategory !== "" && theChosenCategory !== null
      ?
        theChosenCategory.data.length === 0 ?
          <Empty
            className="hp-my-8"
            image={emptyImage}
            imageStyle={{
              height: 160,
            }}
            description={
              <span className="hp-text-color-dark-30">No {theCategoryInfo.name} yet.<br /><br /><Button type="primary" onClick={addItem}>Add first {theCategoryInfo.name}</Button></span>
            }
          />
        :
        <>
        {showcase}
        <br /><Button type="primary" onClick={addItem}>Add another {theCategoryInfo.name}</Button>
        </>
      :
      ""
      }
    </div>
  );
}
          