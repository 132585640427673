import { lazy } from "react";
import {isMobile, isMobileOnly, isTablet} from 'react-device-detect';

const selectedLayout = isMobile ? isMobileOnly ? "BottomLayout" : "VerticalLayout" : "HorizontalLayout"

const PagesRoutes = [

  //landing route
  {
    path: "/main",
    component: lazy(() => import("../../view/landing")),
    layout: "FullLayout",
  },
  //Admin Routes
  {
    path: "/adminpanel/dashboard",
    component: lazy(() => import("../../view/common/pages/blank")),
    layout: "VerticalLayout",
  },
  //Admin Authentication Pages
  {
    path: "/adminx/auth/login",
    component: lazy(() => import("../../view/admin/authentication/login")),
    layout: "FullLayout",
  },












  //Organizer Routes
  {
    path: "/greenroom/dashboard",
    component: lazy(() => import("../../view/organizer/pages/dashboard" /* webpackChunkName: "groomdash" */)),
    layout: "VerticalLayout",
  },
  {
    path: "/greenroom/setup-event",
    component: lazy(() => import("../../view/organizer/pages/eventsetup" /* webpackChunkName: "groomstpevent" */)),
    layout: "VerticalLayout",
  },
  {
    path: "/greenroom/setup-agenda",
    component: lazy(() => import("../../view/organizer/pages/agendasetup" /* webpackChunkName: "groomstpagenda" */)),
    layout: "VerticalLayout",
  },
  {
    path: "/greenroom/setup-speaker",
    component: lazy(() => import("../../view/organizer/pages/speakersetup" /* webpackChunkName: "groomstpspeakert" */)),
    layout: "VerticalLayout",
  },
  {
    path: "/greenroom/live-notification",
    component: lazy(() => import("../../view/organizer/pages/livenotification" /* webpackChunkName: "groomtoolnoti" */)),
    layout: "VerticalLayout",
  },
  {
    path: "/greenroom/follow-me",
    component: lazy(() => import("../../view/organizer/pages/followme" /* webpackChunkName: "groomfollow" */)),
    layout: "VerticalLayout",
  },
  {
    path: "/greenroom/live-analytics",
    component: lazy(() => import("../../view/organizer/pages/analytics" /* webpackChunkName: "groomtoolanalytics" */)),
    layout: "VerticalLayout",
  },
  //Organizer Authentication Pages
  {
    path: "/organizer/auth/login",
    component: lazy(() => import("../../view/organizer/authentication/login" /* webpackChunkName: "orgin" */)),
    layout: "FullLayout",
  },
  {
    path: "/organizer/auth/forgot",
    component: lazy(() =>
      import("../../view/organizer/authentication/recover-password" /* webpackChunkName: "orgfor" */)
    ),
    layout: "FullLayout",
  },
  {
    path: "/organizer/auth/register",
    component: lazy(() => import("../../view/organizer/authentication/register" /* webpackChunkName: "orgreg" */)),
    layout: "FullLayout",
  },










  //Virtual User Routes
  {
    path: "/realm/home",
    component: lazy(() => import("../../view/virtual/pages/main" /* webpackChunkName: "userhm" */)),
    layout: selectedLayout,
  },
  {
    path: "/realm/:realmid",
    component: lazy(() => import("../../view/virtual/pages/realmViewer" /* webpackChunkName: "usermain" */)),
    layout: selectedLayout,
  },
  {
    path: "/realm/profile",
    component: lazy(() => import("../../view/common/pages/blank" /* webpackChunkName: "userprofile" */)),
    layout: "HorizontalLayout",
  },
  //Virtual User Authentication Pages
  {
    path: "/virtual/auth/login",
    component: lazy(() => import("../../view/virtual/authentication/login" /* webpackChunkName: "userin" */)),
    layout: "FullLayout", 
  },
  {
    path: "/virtual/auth/forgot",
    component: lazy(() =>
      import("../../view/virtual/authentication/recover-password" /* webpackChunkName: "userfor" */)
    ),
    layout: "FullLayout",
  },
  {
    path: "/virtual/auth/register",
    component: lazy(() => import("../../view/virtual/authentication/register" /* webpackChunkName: "userreg" */)),
    layout: "FullLayout",
  },
  {
    path: "/virtual/auth/resetpassword",
    component: lazy(() =>
      import("../../view/virtual/authentication/reset-password" /* webpackChunkName: "userreset" */)
    ),
    layout: "FullLayout",
  },

  //Common Pages
  {
    path: "/pages/blank-page",
    component: lazy(() => import("../../view/common/pages/blank")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/error-page",
    component: lazy(() => import("../../view/common/pages/error")),
    layout: "FullLayout",
  },
];

export default PagesRoutes;