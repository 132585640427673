import React from 'react'
import { Card, Row, Col, Progress, Radio, Tooltip, Badge } from 'antd'
import { 
  CheckCircleTwoTone, 
  CloseCircleTwoTone, 
  // ClockCircleTwoTone 
} from '@ant-design/icons'
import { useDispatch, useSelector } from "react-redux";
import actions from 'redux/virtual/streamSession/actions'
import './pollcomponent.less'
import { log } from '@craco/craco/lib/logger';

export default function PollComponent(props) {
  const { data } = props
  const dispatch = useDispatch();
  const requiredState = useSelector(({virtualUser})=>({virtualUser}))
  const {virtualUser: {roles}} = requiredState

  const allowMessage = () => {
    
    dispatch({
      type: actions.ALLOW_POLL,
      payload: {
        messageID: data.message.message_id,
      },
    })
  }

  const hidePoll = () => {
    dispatch({
      type: actions.HIDE_POLL,
      payload: {
        messageID: data.message.message_id,
      },
    })
  }

  const onPollSelect = event => {
    dispatch({
      type: actions.ANSWER_POLL,
      payload: {
        messageID: data.message.message_id,
        optionID: event.target.value,
      },
    })
  }

    const radioStyle = {
      display: 'block',
      // height: '30px',
      // lineHeight: '30px',
    }

    let totalVotes = 0
    Object.entries(data.message.options).map(key => {
      totalVotes += key[1].votes
      return ''
    })
    console.log("pollingcomponent")
    const thePolls = []
    Object.entries(data.message.options).map(element => {
      const currentPercetage = (element[1].votes / totalVotes) * 100
      const finalPer = Math.round((currentPercetage + Number.EPSILON) * 100) / 100
      thePolls[element[1].position] =  (
        <Row justify="space-around" align="middle" className="pollOption" key={element[0]}>
          <Col span="24">
            {data.message.answered ? (
              <>
                <Tooltip placement="topLeft" title={`${element[1].votes} votes`} arrowPointAtCenter>
                  <Progress
                    strokeColor={{
                      from: '#108ee9',
                      to: '#87d068',
                    }}
                    percent={finalPer}
                    status="active"
                  />
                  <h5>{element[1].option}</h5>
                </Tooltip>
              </>
            ) : (
              <Radio
                style={radioStyle}
                value={element[0]}
                disabled={
                  data.message.moderationStatus === 'pending' ||
                  data.message.moderationStatus === 'sentforapproval'
                }
              >
                {element[1].option}
              </Radio>
            )}
          </Col>
        </Row>
      )
      return ''
    })

    const pollStatusClass = data.message.active ? "title published" : "title unpublished"
    const theAction = roles.includes('ROLE_MODERATOR') ? data.message.active ? [<span onClick={hidePoll}><CloseCircleTwoTone twoToneColor="#eb2f96" /> Hide Poll</span>] : [<span onClick={allowMessage}><CheckCircleTwoTone twoToneColor="#52c41a" /> Show Poll</span>] : []
    return (
      <Card style={{ width: '100%' }} actions={theAction} className="thepollcomponent">
        {roles.includes('ROLE_MODERATOR')
        ?
          <>
            <Row justify="space-around" align="middle" className={pollStatusClass}>
              <Col span="24" className="text-center">
                {data.message.active ? <Badge status="error" text="Live Now" /> : <Badge status="default" text="Unpublished" />}
              </Col>
            </Row>
            <br />
          </>
        :
          ""
        }
        <div className="pollbody">
          {data.message.answered && !roles.includes('ROLE_MODERATOR')
            ? 
              "" 
            :
              <Row justify="space-around" align="middle">
                <Col span="24">
                  <h3>{data.message.question}</h3>
                </Col>
              </Row>
          }
          {data.message.answered ? (
            roles.includes('ROLE_MODERATOR') ? thePolls :
            <>
              <h4>Thanks for responding</h4>
              {/* <h5><b>#GrowthKaTime</b></h5> */}
            </>
          ) : (
            <Radio.Group onChange={onPollSelect}>{thePolls}</Radio.Group>
          )}
        </div>
      </Card>
    )
  }
