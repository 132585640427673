import React from 'react'

import AltyugCoin from "assets/images/icons/altyugcoin.png";
import '../leaderboard.less'

/* eslint-disable react/jsx-indent, camelcase */

function PointingContainer (props) {
    const { points } = props
    return (
      <div>
        <span className="user_points">
          <div className="pointsContainer">
            {points > 0 ? (
              <span className="pointArea">
                {points} <img src={AltyugCoin} alt="coin" />
              </span>
            ) : (
              'NA'
            )}
          </div>
        </span>
      </div>
    )
}

export default PointingContainer
