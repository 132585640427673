import React from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  Form,
  Input,
  InputNumber
} from 'antd'
import ReactPlayer from 'react-player';
import "../../../editor.less"

export default function Preview(props) {
  const {theRow,currentEditorScreen} = props
  const {url,name,isMuted,showControls,loop} = theRow
  
  let showcase = ""
  if(url !== "")
  {
    console.log("inside preview")
  console.log(theRow)
  console.log(currentEditorScreen)
  console.log(url,name,isMuted,showControls,loop)
    if(currentEditorScreen === "products")
    {
      showcase = <model-viewer
        // className="model-viewer"
        src={url}
        alt="A rock"
        exposure="1"
        camera-controls
        auto-rotate
        ar
        ar-modes="webxr"
        style={{width: "100%", height: "50vh"}}
      ></model-viewer>
    }
    else if(currentEditorScreen === "videos")
    {
      showcase = <ReactPlayer
        url={url}
        // playing
        controls={showControls}
        width="100%"
        // height={height}
        volume={isMuted}
        loop={loop}
      />
    }
    else if(currentEditorScreen === "images")
    {
      showcase = <img src={url} style={{width: "100%"}} alt={name} />
    }
  }
  
  console.log("we are inside preview")
  console.log(showcase)
  return (
    showcase
  );
}
          