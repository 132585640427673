import React, {useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useLocation,withRouter } from "react-router";
import actions from "../redux/common/settings/actions";

function AuthRoute(props) {
    const requiredState = useSelector(({ virtualUser, organizer, theAdmin }) => ({ virtualUser,organizer, theAdmin }));
    const dispatch = useDispatch();
    const { virtualUser,organizer,theAdmin } = requiredState;
    const {children} = props;
    const location = useLocation();
    const { pathname } = location

    const getLayout = () => {
        if (pathname === '/' || pathname === '/main') { return "landing" }
        else if (/^\/virtual\/auth(?=\/|$)/i.test(pathname)) { return "userlogin" }
        else if (/^\/realm(?=\/|$)/i.test(pathname)) { return "event" }
        else if (/^\/organizer\/auth(?=\/|$)/i.test(pathname)) { return "organizerlogin" }
        else if (/^\/greenroom(?=\/|$)/i.test(pathname)) { return "greenroom" }
        else if (/^\/adminx\/auth(?=\/|$)/i.test(pathname)) { return "adminlogin" }
        else if (/^\/adminpanel(?=\/|$)/i.test(pathname)) { return "adminpanel" }
        return "userlogin"
    }
    
    const arena = getLayout()
    useEffect(() => {
      dispatch({
        type: actions.SET_STATE,
        payload: {currentArena: arena}
      })
    }, [pathname]);

    
    let isOrganizerAuthorized = false
    let isUserAuthorized = false
    let isAdminAuthorized = false

    if(arena !== "landing")
    {
      if (arena === 'event' || arena === "userlogin") { isUserAuthorized = virtualUser.authorized } 
      else if (arena === 'greenroom' || arena === "organizerlogin") { isOrganizerAuthorized = organizer.authorized } 
      else if (arena === 'adminpanel' || arena === "adminlogin") { isAdminAuthorized = theAdmin.authorized } 
    }

    if (arena === 'event' && !isUserAuthorized) { return <Redirect to="/virtual/auth/login" /> }
    else if (arena === "userlogin" && isUserAuthorized) { return <Redirect to="/realm/home" /> }
    // else if (arena === 'greenroom' && !isOrganizerAuthorized) { return <Redirect to="/organizer/auth/login" /> } //Uncomment this line when Organizer signup and login API is sorted.
    else if (arena === "organizerlogin" && isOrganizerAuthorized) { return <Redirect to="/greenroom/dashboard" /> }
    else if (arena === 'adminpanel' && !isAdminAuthorized) { return <Redirect to="/adminx/auth/login" /> }
    else if (arena === "adminlogin" && isAdminAuthorized) { return <Redirect to="/adminpanel/dashboard" /> }
    return children;
};

export default withRouter(AuthRoute)