import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Input, Popover,Tooltip, Button, Space } from 'antd'
import uuid from 'react-uuid'
import actions from 'redux/virtual/streamSession/actions'
import { SmileOutlined, SendOutlined, InfoCircleOutlined, UserOutlined } from '@ant-design/icons'
import EmojiPicker from 'emoji-picker-react';
import { RiEmotionLaughLine, RiSendPlaneLine } from 'react-icons/ri';

import "../../streamFeatures.less"
import { isMobile } from 'react-device-detect';

export default function ChatFooter(props) {
  const [textMessage, setTextMessage] = useState("");
  const dispatch = useDispatch();
  const requiredState = useSelector(({ streamSession, virtualUser,eventApp,altRealm }) => ({ streamSession,eventApp, virtualUser ,altRealm}))
  const {
    virtualUser: { id, name, avatarType, avatarColor, avatarData },
    streamSession: { currentReplyTo, currentScreen ,currentChatScenario},
    altRealm:{ProfileImage}

  } = requiredState
  const sendMessage = (message, messageType) => {

    const messageFinal = {
      message_id: uuid(),
      type: messageType,
      moderationStatus: 'sentforapproval',
      publisher: id,
      content: message,
      title: name,
      avatar:
      ProfileImage,
      reply: {},
    }
    if (Object.keys(currentReplyTo).length > 0) {
      messageFinal.reply = {
        photoURL: currentReplyTo.avatar,
        title: currentReplyTo.title,
        titleColor: '#FF0000',
        message: currentReplyTo.actualtext,
      }
    }

    dispatch({
      type: messageType === 'liveChat' ? actions.SEND_MESSAGE : actions.SEND_QUESTION,
      payload: {
        message: messageFinal,
      },
    })

    dispatch({
      type: actions.SET_STATE,
      payload: {
        currentReplyTo: {},
      },
    })
  }

  const addEmoji = (event,emojiObject) => {

    const textEmoji = `${textMessage}${event.emoji}`
    setTextMessage(textEmoji)
  }

  const onPressEnterLive = () => {
    if (textMessage !== '') {
      sendMessage(textMessage, 'liveChat')
      setTextMessage("")
    }
  }

  const onPressEnterQA = () => {
    if (textMessage !== '') {
      sendMessage(textMessage, 'qanda')
      setTextMessage("")
    }
  }

  useEffect(() => {
    {isMobile && dispatch({
      type: actions.SET_STATE,
      payload: {
        currentChatScenario: 'worldchat',
      },
    })}
    
  }, [])
  
  const changeScenario = (val) => {
    dispatch({
      type: actions.SET_STATE,
      payload: {
        currentChatScenario: val,
      },
    })
  }

  const replyClick = () => {
    dispatch({
      type: actions.SET_STATE,
      payload: {
        currentReplyTo: {},
      },
    })
  }
  return (
    <>
      {/* <Row
        className={
          Object.keys(currentReplyTo).length > 0
            ? "footerTop footerTopOpened"
            : "footerTop"
        }
      >
        <Col span={24}>
          <Row justify="space-around" align="middle" gutter={16} className="replyContent">
            <Col span={21}>
              <span>
                <b>{currentReplyTo.title}</b>
              </span>
              <br />
              <span>{currentReplyTo.text}</span>
            </Col>
            <Col span={2}>
              <CloseOutlined onClick={replyClick} />
            </Col>
            <Col span={1} />
          </Row>
        </Col>
      </Row> */}
      <Row className="footer">
        <Col span={24}>
          <Input
            placeholder={
              currentScreen === 'liveChat'
                ? "Type here to Chat"
                : "Type your Question here"
            }
            value={textMessage}
            onChange={event => setTextMessage(event.target.value)}
            onPressEnter={
              currentScreen === 'liveChat' ? onPressEnterLive : onPressEnterQA
            }
            className="chatFooter"
            suffix={
              <>
                <Popover
                  placement="top"
                  overlayStyle={{ padding: '0 0' }}
                  content={
                    <EmojiPicker 
                      Theme="dark"
                      onEmojiClick={addEmoji}
                      lazyLoadEmojis={true}
                    />
                  }
                  trigger="click"
                >
                  <RiEmotionLaughLine className="chatIcons" />
                </Popover>
                <RiSendPlaneLine className="chatIcons" onClick={currentScreen === 'liveChat' ? onPressEnterLive : onPressEnterQA} />
              </>
            }
          />
        </Col>
      </Row>
      <Row className="footer lowerfooter">
        {!isMobile &&   <Col span={11} className="hp-text-center">
            <p className={currentChatScenario === "inroom" ? 'chatscenario scenarioactive' : 'chatscenario'} onClick={() => changeScenario("inroom")}>In-Room Chat</p>
          </Col> }
        
          <Col span={1} />
          <Col span={11} className="hp-text-center active">
            <p className={currentChatScenario === "inroom" ? 'chatscenario' : 'chatscenario scenarioactive'} onClick={() => changeScenario("worldchat")}>World Chat</p>
          </Col>
      </Row>
    </>
  )
}