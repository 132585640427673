const actions = {
    SET_STATE: 'altRealm/SET_STATE',
    GET_INIT: 'altRealm/GET_INIT',
    SEND_UNITY_MESSAGE: 'altRealm/SEND_UNITY_MESSAGE',
    SEND_AVATAR: 'altRealm/SEND_AVATAR',
    SEND_REALM_DATA: 'altRealm/SEND_REALM_DATA',
    SET_DATA: 'altRealm/SET_DATA',
    GET_AVATAR_RENDER: 'altRealm/GET_AVATAR_RENDER',
    UPDATE_ELEMENT_SCORE: 'altRealm/UPDATE_ELEMENT_SCORE'
  }
  
  export default actions  