import React from 'react';
import {
  Row,
  Col,
  Button,
} from 'antd'
import { useDispatch, useSelector } from "react-redux";
import actions from 'redux/virtual/realmEditor/actions';
import EditorLogo from "assets/images/icons/editor.png"
import "../../editor.less"
import ItemIcon from './ItemIcon';
import { useEffect } from 'react';

export default function Sidebar(props) {
  const dispatch = useDispatch();
  const requiredState = useSelector(({realmEditor})=>({realmEditor}))
  const {realmEditor:{currentEditorScreen}} = requiredState
  const {options} = props
  const handleIconClick  = (theKey) => {
    dispatch({
      type: actions.SET_STATE,
      payload: {
        currentEditorScreen: theKey,
      },
    })
  }

  useEffect(() => {
    if(currentEditorScreen === "")
    {
      dispatch({
        type: actions.SET_STATE,
        payload: {
          currentEditorScreen: Object.keys(options)[0]
        },
      })
    }

  }, []);

  const sidebarOptions = Object.entries(options).map((key) => {
    // const tempcurrentEditorScreen = currentEditorScreen === "" ? Object.keys(options)[0] : currentEditorScreen
    const theClassName = currentEditorScreen === key[0] ? "itemicon itemactive" : "itemicon"
    if(key[1].slots > 0)
    {
      return(
        <>
        <ItemIcon name={key[1].name} icon={key[1].icon} theClassName={theClassName} theItemClick={() => handleIconClick(key[0])} />
        <br />
        </>
      )
    }

  })

  return (
    <>
    <Row justify="space-around" align="top" className="hp-text-center siderbar" style={{ borderRadius: "20px 0 0 0"}}>
      <img
            src={EditorLogo}
            alt="Altyug Editor Logo"
            style={{ width: '60%' }}
          />
    </Row>
    <Row justify="space-around" align="middle" className="hp-text-center siderbar" style={{ minHeight: "calc(90vh - 100px)", borderRadius: "0 0 0 20px"}}>
      <Col span={24}>
        {sidebarOptions}
      </Col>
    </Row>
    </>
  );
}
          