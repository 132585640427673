import React from 'react';
import {Modal} from 'antd'
import { useDispatch, useSelector } from "react-redux";
import actions from 'redux/virtual/altRealm/actions';
import "./interactionModal.less"
import {
  CloseCircleTwoTone
} from '@ant-design/icons';
import PhotoboothAvatar from './components/PhotoboothAvatar';
import ImageView from './components/ImageView';

export default function InteractionModal() {
  const requiredState = useSelector(({altRealm})=>({altRealm}))
  const dispatch = useDispatch();
  const {altRealm:{isInteractionModalVisible,interactionModalModule}} = requiredState

  const closeModal  = () => {
    dispatch({
      type: actions.SET_STATE,
      payload: {
        isInteractionModalVisible: false,
        interactionModalModule: "",
        interactionIndex: ""
      },
    })
  }

  const availableModule = {
    imageView: <ImageView />,
    "3dphotoBooth": <PhotoboothAvatar />
  }

  return (
    <Modal
      visible={isInteractionModalVisible}
      wrapClassName="interactionModal"
      maskClosable={false}
      mask
      width="70vw"
      height="90vh"
      centered
      onOk={closeModal}
      confirmLoading
      onCancel={closeModal}
      footer={null}
      closeIcon={<CloseCircleTwoTone twoToneColor="#eb2f96" style={{ fontSize: '25px' }} />}
      maskStyle={{ backgroundColor: 'rgba(27, 27, 27, 0.3)' }}
      destroyOnClose
    >
      {availableModule[interactionModalModule]}
    </Modal>
  );
}
          