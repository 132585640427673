import PubNub from 'pubnub'
import API from 'configs/apiConfig'
const stageId = process.env.REACT_APP_POLL_STAGE_ID

export async function getallUsers(data) {
  return API.get(`getAllUsers?page=${data.currentPagination}&size=${data.maxUsers}`, {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  })
}

export async function searchUsers(data) {
  console.log('lets check url')
  console.log(
    `searchUsers?page=${data.currentPagination}&size=${data.maxUsers}&search=${data.searchkey}`,
  )
  return API.get(
    `searchUsers?page=${data.currentPagination}&size=${data.maxUsers}&search=${data.searchkey}`,
    {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    },
  )
}

export async function pubnubUnSubscribe(data) {
  const { channels } = data
  return data.pubnub.unsubscribe({
    channels,
  })
}

export async function initChat(userid) {
  console.log("initializing chat")
  console.log(process.env.REACT_APP_PUBNUB_PUBLISHKEY)
  console.log(process.env.REACT_APP_PUBNUB_SUBSCRIBEKEY)
  return new PubNub({
    publishKey: process.env.REACT_APP_PUBNUB_PUBLISHKEY,
    subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBEKEY,
    uuid: userid,
    autoNetworkDetection: true, // enable for non-browser environment automatic reconnection
    restore: true, // enable catchup on missed messages
  })
}

export async function saveChat(data) {
  const finalData = {
    ChannelName: data.ChannelName,
    ChannelType: data.ChannelType,
    ChatWith: data.ChatWith,
    assignedTo: data.assignedTo,
  }
  return API.post('saveChat', finalData, {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  })
}

export async function savePollResponse(data) {
  return API.post('saveStagePollResponse', {...data,stageID:stageId}, {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  })
}

export async function savePoll(data) {
  console.log(data);
  return API.post('saveStagePoll', {...data,stage_id:stageId}, {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  })
}

export async function approvePolls(data) {
  return API.post(
    'approvePolls',
    { messageID: data.messageID },
    {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    },
  )
}

export async function publishPolls(data) {
  return API.get(`activatePoll/${data.messageID}`)
}

export async function hidePolls(data) {
  return API.get(`deactivatePoll/${data.messageID}`)
}

export async function pubnubPublish(data) {
  return data.pubnub.publish({
    channel: data.channelID,
    message: data.message,
  })
}

export async function pubnubSignalPublish(data) {
  return data.pubnub.signal({
    channel: data.channelID,
    message: data.message
  })
}

export async function pubnubSubscribe(data) {
  const { channels } = data
  return data.pubnub.subscribe({
    channels:channels
  })
}

export async function pubnubFetchMessages(data) {
  const { channels } = data
  console.log('virtualuser4', channels)
  return data.pubnub.fetchMessages({
    channels,
    count: 100,
  })
}

export async function getChannels(accessToken) {
  return API.get('getChannels', {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
}

export async function fetchPolls(data) {
  return API.get(`getStagePolls/${stageId}`, {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  })
}

export async function getLeads(theScene) {
  return API.get(`getLead/${theScene}`)
}

export async function emailNotes(data) {
  return API.post(
    `emailNotes`,
    { notes: data.notes },
    {
      headers: {
        Authorization: `Bearer ${data.accessToken}`,
      },
    },
  )
}

export async function requestRaiseHand(data) {
  return API.get(`requestRaiseHand/${data.sessionId}`,
    {
      headers: {
        Authorization: `Bearer ${data.accessToken}`,
      },
    },
  )
}

export async function raiseHandRequestStatus(data) {
  return API.get(`raiseHandRequestStatus/${data.sessionID}`, {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  })
}

export async function getAllHandsRaised(data) {
  return API.get(`getAllHandsRaised/${data.sessionID}`)
}

export async function fetchAvailableVMixSlots(data) {
  return API.get(`fetchAvailableVMixSlots/${data.sessionID}`)
}

export async function assignVmixCall(data) {
  return API.get(`assignVmixCall/${data.callid}`,
    {
      headers: {
        Authorization: `Bearer ${data.accessToken}`,
      },
    },
  )
}

export async function releaseVmixCall(data) {
  return API.get(`releaseVmixByUser`,
    {
      headers: {
        Authorization: `Bearer ${data.accessToken}`,
      },
    },
  )
}
