import React, {useEffect, useState} from 'react';
import {
  Modal,
  // Row,
  // Col,
  // Button,
} from 'antd'
import WelcomeScreen from './WelcomeScreen';
import TheEditor from "./TheEditor"
import { CloseCircleTwoTone } from '@ant-design/icons'
import { useDispatch, useSelector } from "react-redux";
import actions from 'redux/virtual/realmEditor/actions';
import "./editor.less"

export default function RealmEditor() {
  const requiredState = useSelector(({realmEditor})=>({realmEditor}))
  const dispatch = useDispatch();
  const {realmEditor:{isRealmEditorOpen,currentStep}} = requiredState
  const steps = [
    <WelcomeScreen />,
    <TheEditor />
  ]
  const closeModal  = () => {
    dispatch({
      type: actions.SET_STATE,
      payload: {
        isRealmEditorOpen: false,
        currentStep: 0
      },
    })
  }

  return (
    <Modal
      visible={isRealmEditorOpen}
      wrapClassName="editorModal"
      maskClosable={false}
      mask
      width="80vw"
      height="90vh"
      centered
      onOk={closeModal}
      confirmLoading
      onCancel={closeModal}
      footer={null}
      closeIcon={<CloseCircleTwoTone twoToneColor="#eb2f96" style={{ fontSize: '25px' }} />}
      maskStyle={{ backgroundColor: 'rgba(27, 27, 27, 0.3)' }}
      destroyOnClose
    >
      {steps[currentStep]}
    </Modal>
  );
}
          