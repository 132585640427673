import React from 'react';
import {
  Row,
  Col,
  Button,
} from 'antd'
import { useDispatch, useSelector } from "react-redux";
import actions from 'redux/virtual/altRealm/actions';
import EditorLogo from "assets/images/icons/editor.png"
import "../photobooth.less"

export default function WelcomeScreen() {
  const dispatch = useDispatch();
  const startEditor  = () => {
    dispatch({
      type: actions.GET_AVATAR_RENDER,
    })
  }

  return (
    <Row justify="space-around" align="middle" className="hp-text-center fullHeightScreen">
      <Col span={7} />
      <Col span={10} className="text-center">
        <img
          src={EditorLogo}
          alt="Altyug Editor Logo"
          style={{ width: '70%' }}
        />
        <br />
        <br />
        <br />
        <Button type="primary" onClick={startEditor}>
          Start Photobooth
        </Button>
      </Col>
      <Col span={7} />
    </Row>
  );
}
          