import React, { useState } from "react";

import { useSelector } from "react-redux";

import { Layout, Row, Col } from "antd";

import HeaderBottom from "./components/header/HeaderBottom";
import ScrollTop from "./components/scroll-to-top";
import Interactions from "./components/Interactions";

const { Content } = Layout;

export default function BottomLayout(props) {
    const { children } = props;
    // const [visible, setVisible] = useState(false);

    // Redux
    const customise = useSelector(state => state.customise)

    return (
        <Layout className={`hp-app-layout hp-bg-color-dark-90 ${customise.navigationBg && 'hp-app-layout-bg'}`}>
            <Interactions />
            <Content className="hp-content-main-bottom">
                <Row justify="center">
                    {
                        customise.contentWidth == "full" && (
                            <Col span={24}>
                                {children}
                            </Col>
                        )
                    }

                    {
                        customise.contentWidth == "boxed" && (
                            <Col xxl={20} xl={22} span={24}>
                                {children}
                            </Col>
                        )
                    }
                </Row>
            </Content>
            {/* <HeaderBottom visible={visible} setVisible={setVisible} /> */}
            <ScrollTop />
        </Layout>
    );
};