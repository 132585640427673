import actions from './actions'

const initialState = {
  realms: {},
  defaultScene: '',
  currentScene: '',
  isSceneSetupModalOpen: false,
  sceneaddLoading: false,
  roles: {},
  order: [],
  agenda: {},
  saveEventLoader: false,
  imageEditorOpen: false,
  imageEditorFile: {},
  imageUploadLoading: false,
}

export default function eventSetupReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SET_SCENE:
      return {
        ...state,
        realms: {
          ...state.realms,
          [action.payload.currentScene]: {
            ...state.realms[action.payload.currentScene],
            [action.payload.key]: action.payload.actualload,
          },
        },
      }
    default:
      return state
  }
}
