import React, { useState } from "react";
import {Row,Col,Card} from 'antd'
// import Iframe from "react-iframe";
import { useDispatch, useSelector } from "react-redux";
// import actions from 'redux/virtual/altRealm/actions';
import WelcomeScreen from "./WelcomeScreen";
import ThePhotobooth from "./ThePhotobooth";

export default function PhotoboothAvatar() {
  const requiredState = useSelector(({altRealm})=>({altRealm}))
  const {altRealm: {photoboothStep}} = requiredState
  const steps = [
    <WelcomeScreen />,
    <ThePhotobooth />
  ]

//   const showcase = Object.entries(gameObject).map((key) => {
//     console.log("checking showcase")
//     console.log(key)
    
//     return(
//       <>
//       <Col span={1} />
//       <Col span={22}>
//         <Card bordered={false} className="hp-text-center" style={{ width: "100%", cursor: "pointer" }} onClick={() => {openGame(key[1].gameurl)}}>
//           <img src={key[1].gameIcon} style={{width: "100%"}} />
//           <br />
//           <h3>{key[1].gamename}</h3>
//         </Card>
//       </Col>
//       <Col span={1} />
//       </>
//     )
//   })
  return (
    steps[photoboothStep]
  );
}
          