import React, { useEffect, useRef, useState } from 'react'
import { Row, Col, List } from 'antd'
import { Scrollbars } from 'react-custom-scrollbars'
import { useDispatch, useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import { DownCircleOutlined } from '@ant-design/icons'
import PollComponent from '../PollComponent'
import './react-chat-elements.modified.css'
import "../../streamFeatures.less"

let isInitialLoad = false
export default function PollingContent(props) {
  let pollingscrollbars = useRef();
  const dispatch = useDispatch();
  const requiredState = useSelector(({streamSession})=>({streamSession}))
  const {streamSession: {polling}} = requiredState
  const [mounted, setMounted] = useState(false);
  useEffect(() => {setMounted(true)
  
  console.log("inside pollcomp 1");
  },[]);
  console.log("inside pollcomp 2");

  useEffect(() => {
    if(!isInitialLoad) 
    {
      if(Object.keys(polling).length > 0){
        mounted && pollingscrollbars.current.scrollToBottom()
        isInitialLoad = true
      }
    }

    if(pollingscrollbars.current !== null){
      const pollingValues = pollingscrollbars.current.getValues()
      if(pollingValues.top > 0.80)
      {
        mounted && pollingscrollbars.current.scrollToBottom()
      }
    }
  },[polling])

  const takemetopollingbottom = () => {
    pollingscrollbars.current.scrollToBottom()
  }

    const pollingSeralized = []
    Object.entries(polling).map(key => {
      // if(key[1].message.active || roles.includes('ROLE_MODERATOR'))
      // {
        pollingSeralized.push(key[1])
      // }
      return ''
    })
    console.log("inside pollcomp 3");
    console.log(polling)
    const dataSource = pollingSeralized
    let pollingActivateBottom = false
    let pollingAllValues = {top: 1}
    if(pollingscrollbars.current !== null && pollingscrollbars.current !== undefined){
      pollingActivateBottom = true
      pollingAllValues = pollingscrollbars.current.getValues()
    }

    let width = "100%"
    let height = "100%"
  
    if(isMobile)
    {
      width = window.innerWidth
      height = 0.5625*width
    }
    const theContainer = height + 106 

    return (
      <>
        <Row className='pollcomponent' >
          <Col span={24} >
            <Scrollbars ref={pollingscrollbars} autoHeight autoHeightMin={50} autoHeightMax={`calc(90vh - 100px)`}>
              {console.log("pollactive")}
              <List
                dataSource={dataSource}
                locale={{emptyText : "No Active Polls"}}
                renderItem={item => (
                  <List.Item>
                    <PollComponent data={item} />
                  </List.Item>
                )}
                style={{ borderBottom: 0, bottom: 0}}
              />
            </Scrollbars>
          </Col>
        </Row>

        {pollingActivateBottom ? 
          <div className="backtobottom" style={{display: pollingAllValues.top < 0.84 && pollingAllValues.clientHeight !== pollingAllValues.scrollHeight ? "block" : "none"}}>
            <DownCircleOutlined onClick={takemetopollingbottom} />
          </div>
        :
          ''
        }
      </>
    )
}
