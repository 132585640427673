import API from '../../configs/apiConfig'

export async function updateRealmScene(data) {
  
    return API.post(`updateRealmSceneData?scene_id=${data.realmID}`, data.categories, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }


  export async function getSceneSavedData() {
    return API.get('getRealmScene')
}


export async function getAvatarDetails(data) {

 let url = data.replace(/([.]\w+)$/, '')
  return API.get(`${url}.json`, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export async function updateTouchPointScore(data) {
  return API.get(`updateTouchPointScore?touchPointId=${data.touchPointId}&elementId=${data.elementId}&touchPointName=${data.touchPointName}`, {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  })
}
