import React from 'react'
import { all, takeEvery, call, put, select } from 'redux-saga/effects'
import { notification } from 'antd'
import capitalizeFirstLetters from 'capitalize-first-letters'
import {
  login,
  logout,
  saveFailedLogin,
} from '../../../services/virtual/user'
import actions from './actions'

export function* LOGIN(data) {
  try {
    const result = yield call(login, { finalData: data.payload })
    if (result.status === 200) {
      if (result.data.active_status) {
        if (!result.data.kick_out) {
          if (!result.data.login_status) {
            if (result.data.payment_status) {
              localStorage.setItem('acctoken', result.data.accessToken)
              localStorage.setItem('userid', result.data.id)
              localStorage.setItem('tokenType', result.data.tokenType)
              localStorage.setItem('userData', JSON.stringify(result.data.userData))
              yield put({
                type: actions.LOGIN_HELPER,
                payload: {
                  userData: result.data.userData,
                  id: result.data.id,
                  tokenType: result.data.tokenType,
                  accessToken: result.data.accessToken,
                },
              })
            } else {
              notification.error({
                message: 'Trouble with account',
                duration: 12,
                description:
                  'Your account is not yet approved. It could be because of incomplete registration process. Kindly contact the admin right now.',
              })
              localStorage.clear()
              yield put({
                type: actions.SET_STATE,
                payload: {
                  loading: false,
                },
              })
            }
          } else {
            notification.error({
              message: 'Multiple Session Detected',
              duration: 12,
              description: `Your account is logged in another device. Kindly logout from the other device to login here. If you think this is an issue, kindly contact the admin or email us at ${process.env.REACT_APP_SUPPORT_EMAIL}`,
            })
            localStorage.clear()
            yield put({
              type: actions.SET_STATE,
              payload: {
                loading: false,
              },
            })
          }
        } else {
          notification.error({
            message: 'Access Restricted',
            duration: 12,
            description:
              `You do not have the access for this event. If you think this is a mistake, kindly email us at ${process.env.REACT_APP_SUPPORT_EMAIL}`,
          })
          localStorage.clear()
          yield put({
            type: actions.SET_STATE,
            payload: {
              loading: false,
            },
          })
        }
      } else {
        notification.error({
          message: "We haven't started yet.",
          duration: 12,
          description: `The conference will commence at 12 PM HKT on 20th April 2022.`,
        })
        localStorage.clear()
        yield put({
          type: actions.SET_STATE,
          payload: {
            loading: false,
          },
        })
      }
    } else {
      notification.error({
        message: 'Status Invalid',
        duration: 12,
        description:
          'The details entered for logging in are either incorrect or the user is not registered.',
      })
      localStorage.clear()
      yield put({
        type: actions.SET_STATE,
        payload: {
          loading: false,
        },
      })
    }
  } catch (err) {
    console.log(err.response)
    const result = err.response
    if (result.status === 401) {
      notification.error({
        message: 'Unauthorized Access',
        description:
          'The details entered for logging in are either incorrect or the user is not registered.',
      })

      yield put({
        type: actions.SET_STATE,
        payload: {
          loading: false,
        },
      })
    }
    yield call(saveFailedLogin, { email: data.payload.numberOrEmail, deviceInfo: data.payload.deviceInfo })

  }
}

export function* LOGIN_HELPER(data) {
  try {
    const detailResult = data.payload.userData
    const helperMessage = <p>Welcome to {process.env.REACT_APP_EVENT_NAME}</p>
    notification.success({
      message: `Hi ${capitalizeFirstLetters(detailResult.name)}`,
      duration: 8,
      description: helperMessage,
    })

    yield put({
      type: actions.SET_STATE,
      payload: {
        isTutorialVideoVisible: true,
      },
    })

    // yield put({
    //   type: actions.SET_STATE,
    //   payload: {
    //     isEditProfileOpen: true
    //   }
    // })

    // console.log('testing this')
    // console.log(detailResult)

    if (detailResult.roles.includes('ROLE_STALL_CONFIGURATOR')) {
      yield put({
        type: stallConfigActions.SET_STATE,
        payload: {
          isStallDesignerOpen: true,
        },
      })
    }

    yield put({
      type: actions.SET_STATE,
      payload: {
        id: data.payload.id,
        name: capitalizeFirstLetters(detailResult.name),
        number: `+${detailResult.number}`,
        roles: detailResult.roles,
        email: detailResult.email,
        raffle: detailResult.rafale,
        organization: capitalizeFirstLetters(detailResult.organization),
        designation: capitalizeFirstLetters(detailResult.designation),
        avatarType: detailResult.avatar_type,
        avatarData: detailResult.avatar_data,
        avatarColor: detailResult.avatar_color,
        accessToken: data.payload.accessToken,
        tokenType: data.payload.tokenType,
        assignedScene: detailResult.assignedScene,
        authorized: true,
        loading: false,
      },
    })

    // yield put({
    //   type: menuActions.GET_DATA,
    // })

    // yield put({type: actions.GET_ALL_RATINGS})

    // yield put({
    //   type: streamActions.SET_STATE,
    //   payload: {
    //     assignedSessions: detailResult.assignedSessions,
    //   },
    // })
  } catch (err) {
    console.log(err)
  }
}

export function* LOGOUT() {
  // yield call(logout)
  localStorage.clear()
  const state = yield select()
  const {
    virtualUser: { accessToken },
  } = state

  yield call(logout, {
    token: accessToken,
  })

  yield put({
    type: actions.SET_STATE,
    payload: {
      id: '',
      name: '',
      number: '',
      roles: '',
      email: '',
      organization: '',
      designation: '',
      avatarType: '',
      avatarData: '',
      avatarColor: '',
      accessToken: '',
      tokenType: '',
      assignedScene: '',
      authorized: false,
      loading: false,
    },
  })

  window.location.href = '/'
}



export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOGIN_HELPER, LOGIN_HELPER),
    takeEvery(actions.LOGOUT, LOGOUT),

  ])
}