import actions from './actions'

const initialState = {
  isSessionVisible: false,
  streamType: "stage",
  selectedStage: "",
  url: "",
  currentScreen: 0,
  chat: {},
  qanda: {},
  polling: {},
  unreadChatCount: 0,
  unreadqndaCount: 0,
  currentReplyTo: {},
  assignedSessions: [],
  currentSessionDetails: {},
  isSessionFullScreen: false,
  notes: '',
  currentBackstageStep: 0,
  raiseHandRequests: {},
  isHandRaised: false,
  raiseHandLoading: false,
  currentFeature:"",
  currentChatScenario: "inroom"
}

export default function streamReducer(state = initialState, action) {
  try {
    switch (action.type) {
      case actions.SET_STATE:
        return { ...state, ...action.payload }
      case actions.SET_MESSAGE:
        console.log("setting message",state)
       


        if(state[action.payload.key].hasOwnProperty(action.payload.idToSend)){
          console.log(state[action.payload.count] ,state[action.payload.countType],action.payload.count)
          return {
            ...state,
            [action.payload.key]: {
              ...state[action.payload.key],
              [action.payload.idToSend]: state[action.payload.key][action.payload.idToSend].concat(action.payload.toAdd)
            },
            [action.payload.countType]: state[action.payload.countType] + action.payload.count||action.payload.count  ,
          }
        }
        else{
          const theNewChat = []
          theNewChat.push(action.payload.toAdd)
          console.log("newCHat",theNewChat)
          return {
            ...state,
            [action.payload.key]: {
              ...state[action.payload.key],
              [action.payload.idToSend]: theNewChat
            },
            [action.payload.countType]: action.payload.count,
          }
        }
      case actions.SET_QUESTION:
        return {
          ...state,
          qanda: {
            ...state.qanda,
            [action.payload.message_id]: action.payload.toAdd,
          },
          unreadqndaCount: action.payload.count,
        }
      case actions.SET_POLLING:
        console.log("did we reach here?")
        console.log(action.payload)
        return {
          ...state,
          polling: {
            ...state.polling, 
            [action.payload.message_id]: action.payload.toAdd,
          },
        }
      case actions.SET_RAISE_HAND:
        console.log("did we reach here?")
        console.log(action.payload)
        return {
          ...state,
          raiseHandRequests: {
            ...state.raiseHandRequests,
            [action.payload.user_id]: action.payload.toAdd,
          },
        }
      default:
        return state
    }
  } catch (err) {
    console.log(err)
    return state
  }
}
