import React from "react";

import { useSelector } from "react-redux";

import { Layout, Row, Col } from "antd";
import MenuHorizontal from "../menu/item/MenuHorizontal";
import MenuMobile from "../menu/mobile";

const { Header } = Layout;

export default function HeaderHorizontal() {

  // Redux
  const customise = useSelector(state => state.customise)


  // Children
  const headerChildren = () => {
    return (
      <Row
        className="hp-w-100 hp-position-relative"
        align="middle"
        justify="space-between"
      >

        <Col flex="1 0 0">
          <Row className="hp-w-100">
            <Col span={24}>
              <MenuHorizontal position="bottom" />
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }

  return (
    <Header
      className={'hp-header-horizontal-bottom hp-header-full hp-header-bottom hp-lower-menu'}
    >
      <Row className="hp-w-100">
        {
          customise.contentWidth == "full" && (
            <Col span={24}>
              {headerChildren()}
            </Col>
          )
        }

        {
          customise.contentWidth == "boxed" && (
            <Col xxl={20} xl={22} span={24}>
              {headerChildren()}
            </Col>
          )
        }
      </Row>

    </Header>
  );
};