import React from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  Form,
  Input,
  Select,
  Switch
} from 'antd'
import emptyImage from "assets/images/illustrations/empty-groups.svg";
import { useDispatch, useSelector } from "react-redux";
import actions from 'redux/virtual/realmEditor/actions';
import realmActions from 'redux/virtual/altRealm/actions'
import "../../../editor.less"
import uuid from 'react-uuid';
import Preview from '../Preview';
import FileUpload from 'view/common/components/FileUpload';

const {TextArea} = Input
export default function ItemRow(props) {
  const dispatch = useDispatch();
  const {theData,currentEditorScreen,theIndex,theCategoryInfo} = props
  const theRow = theData.data[theIndex]
  const handleIconClick  = (theKey) => {
    dispatch({
      type: actions.SET_STATE,
      payload: {
        currentEditorScreen: theKey,
      },
    })
  }

  const changeInput = event => {
    const theKey = event.target.getAttribute('data-key')
    const val = event.target.value
    updateData(theKey, val)
  }

  const saveFile = (fileUrl, associatedKey) => {
    console.log("have we got the url?")
    console.log(fileUrl)
    // updateScenes(associatedKey, fileUrl)
  }

  const updateData = (key, actualload) => {
    const temptheData = theData.data
    temptheData[theIndex][key] = actualload
    dispatch({
      type: realmActions.SET_DATA,
      payload: {
        currentEditorScreen: currentEditorScreen,
        actualload: temptheData
      },
    })
  }


  const updateProductImages = (index,fileUrl) =>{
    const tempimages = theRow.images
    if(typeof tempimages[index] !== 'undefined') {
      tempimages[index]= fileUrl
    }
    updateData("images", tempimages)
  }

  const addImages = () => {
    const tempimages = theRow.images
    tempimages.push("")
    updateData("images", tempimages)
  }

  let productImages = []
  if(currentEditorScreen === "products")
  {
    theRow.images.forEach((element,index) => {
      productImages.push(<><FileUpload theFile={element} fileType="image" fileSave={(fileUrl) => updateProductImages(index,fileUrl)} /><br /></>)
    });
  }

  return (
    <Card bordered={false} style={{ width: "100%" }} className="itemRowCard">
      <Row justify="space-around" align="middle" className="hp-text-left">
        <Col span={24}>
          <h3>{theCategoryInfo.name} #{theIndex+1}: {theRow.name}</h3>
        </Col>
      </Row>
      <br /><br />
      <Row justify="space-around" align="top" className="hp-text-center">
        <Col span={10}>
          <h5>Preview</h5>
          <br />
          <br />
          <Preview theRow={theRow} currentEditorScreen={currentEditorScreen} />
        </Col>
        <Col span={1} />
        <Col span={13} className="hp-text-left">
          <Form
            name="basic"
            layout="horizontal"
          >
            <Form.Item
              label={`Name of the ${theCategoryInfo.name}`}
              name="namelabel"
            >
              <Input placeholder="Enter the name" onChange={changeInput} data-key="name" name="name" defaultValue={theRow.name} />
            </Form.Item>
            {currentEditorScreen === "images"
            ?
              <FileUpload theFile={theRow.url} fileType="image" associatedKey="imageUrl" fileSave={(fileUrl) => updateData("url",fileUrl)} />
            :
            ""
            }

            {currentEditorScreen === "videos"
            ?
            <Form.Item
              label="URL"
              name="urllabel"
            >
              <Input placeholder="Enter the complete URL" onChange={changeInput} data-key="url" name="url" defaultValue={theRow.url} />
            </Form.Item>
            :
            ""
            }

            {currentEditorScreen === "products"
            ?
              <>
              <Form.Item
                label="URL"
                name="urllabel"
              >
                <FileUpload theFile={theRow.url} fileType="glb" associatedKey="productUrl" fileSave={(fileUrl) => updateData("url",fileUrl)} />
              </Form.Item>
                <Form.Item
                  label="Scale"
                  name="scalelabel"
                >
                  <Input placeholder="Define 3D Model Scale" onChange={changeInput} data-key="scale" name="scale" defaultValue={theRow.scale} />
                </Form.Item>
                <Form.Item
                  label="Rotation"
                  name="rotationlabel"
                >
                  <Input placeholder="Define 3D Model Rotation" onChange={changeInput} data-key="rotation" name="rotation" defaultValue={theRow.rotation} />
                </Form.Item>
                <Form.Item
                  label="Description"
                  name="descriptionlabel"
                >
                  <TextArea placeholder="Give complete description" onChange={changeInput} data-key="description" name="description" defaultValue={theRow.description} />
                </Form.Item>
                <Form.Item
                  label="CTA text"
                  name="ctaTextlabel"
                >
                  <Input placeholder="CTA Text" onChange={changeInput} data-key="ctaText" name="ctaText" defaultValue={theRow.ctaText} />
                </Form.Item>
                <Form.Item
                  label="CTA Link"
                  name="ctaLinklabel"
                >
                  <Input placeholder="Where CTA should redirect to?" onChange={changeInput} data-key="ctaLink" name="ctaLink" defaultValue={theRow.ctaLink} />
                </Form.Item>
                <Card bordered={false} style={{ width: "100%" }}>
                  {productImages}
                  <Button type="primary" onClick={addImages}>Add Product Images</Button>
                </Card>
              </>
            :
            ""
            }
            {currentEditorScreen === "videos"
            ?
              <>
                <Form.Item
                  label="Video Source"
                  name="typelabel"
                >
                  <Select defaultValue={theRow.type} onChange={(value) => {updateData("type",value)}}>
                    <Select.Option value="hls">HLS Live Stream</Select.Option>
                    <Select.Option value="youtube">Youtube</Select.Option>
                    <Select.Option value="mp4">MP4 Video</Select.Option>
                    <Select.Option value="vimeo">Vimeo</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Muted?"
                  name="isMutedlabel"
                >
                  <Switch defaultChecked={theRow.isMuted} checkedChildren="Yes" unCheckedChildren="No" onChange={(value) => {updateData("isMuted",value)}} />
                </Form.Item>
                <Form.Item
                  label="Show Controls?"
                  name="showControlslabel"
                >
                  <Switch defaultChecked={theRow.showControls} checkedChildren="Yes" unCheckedChildren="No" onChange={(value) => {updateData("showControls",value)}} />
                </Form.Item>
                <Form.Item
                  label="Loop Video?"
                  name="looplabel"
                >
                  <Switch defaultChecked={theRow.loop} checkedChildren="Yes" unCheckedChildren="No" onChange={(value) => {updateData("loop",value)}} />
                </Form.Item>
              </>
            :
            ""
            }
            {currentEditorScreen === "images"
            ?
              <>
                <Form.Item
                  label="Description"
                  name="descriptionlabel"
                >
                  <TextArea placeholder="Give complete description" onChange={changeInput} data-key="description" name="description" defaultValue={theRow.description} />
                </Form.Item>
              </>
            :
            ""
            }
          </Form>
        </Col>
      </Row>
    </Card>
  );
}
          