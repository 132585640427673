import thunk from 'redux-thunk'
import createDebounce from 'redux-debounced'
import { createHashHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import createSagaMiddleware from 'redux-saga'
import rootReducer from './rootReducer'
import rootSagas from './rootSagas'
import { createStore, applyMiddleware, compose } from 'redux'

// export const history = createBrowserHistory()
export const history = createHashHistory()
const sagaMiddleware = createSagaMiddleware()
const middleware = [routerMiddleware(history),thunk,sagaMiddleware, createDebounce()]

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(rootReducer(history), {}, composeEnhancers(applyMiddleware(...middleware)))
sagaMiddleware.run(rootSagas)
export default store